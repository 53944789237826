@import '../../../resources/scss/colors.scss';

.searchSuggestionBlock {
  display: flex;
  background-color: $white;
  box-shadow: 0 0.125rem 1.5rem 0 #00000033;
  width: 100%;
  min-height: 100%;

  @media (min-width: 1024px) {
    width: 25.875rem;
    max-height: calc(100vh - var(--top-disclaimer-height) - var(--top-nav-height)); // Account for the height of the top fixed content (TopDisclaimer and TopNav)
    padding: 0 0.75rem 0.75rem 0.75rem;
    border-radius: 0.75rem;
    box-shadow: 0 0.25rem 0.5rem 0 #00000033;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
