.image {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  min-height: 11.875rem;
  padding: 0.75rem;
  border-radius: 0.75rem;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  overflow: hidden;

  @media (min-width: 768px) {
    min-height: 17.375rem;
    padding: 1.5rem;
  }

  @media (min-width: 1024px) {
    min-height: 20.625rem;
  }

  .title {
    h1 {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
      line-clamp: 4;
    }
  }
}
