// List of all colors used across the project

// White, Black
$white: #FFFFFF;
$black: #000000;

// Grey
$grey-10: #F4F4F4;
$grey-20: #EBEBEB;
$grey-30: #E4E5E5;
$grey-40: #D7D7D7;
$grey-50: #CECFCD;
$grey-60: #C3C4C2;
$grey-70: #AFB0AF;
$grey-80: #7D7D7D;
$grey-90: #676868;
$grey-100: #515252;
$digital-grey-5: #F4F4F4;
$digital-grey-10: #E5E5E5;
$digital-grey-15: #D4D4D4;
$digital-grey-30: #A8A8A8;
$digital-grey-40: #949494;
$digital-grey-50: #7D7D7D;
$digital-grey-60: #696969;
$digital-grey-70: #525252;
$digital-grey-80: #3D3D3D;
$digital-grey-95: #1F1F1F;
$digital-grey-100: #141414;
$charcoal-10: #BCBFC7;
$charcoal-20: #8B939E;
$charcoal-30: #606A77;
$charcoal-40: #3E4B59;
$charcoal-50: #1C2B39;
$charcoal-60: #0E1D2B;
$charcoal-70: #141414;

// Blue
$core-blue-10: #B3CDDE;
$core-blue-20: #8AB0C7;
$core-blue-30: #598AAA;
$core-blue-40: #255F82;
$core-blue-50: #013D58;
$core-blue-60: #10253F;
$core-blue-70: #00132B;
$interaction-blue-10: #E1F5FF;
$interaction-blue-20: #81D4FE;
$interaction-blue-30: #3ABCFD;
$interaction-blue-40: #028ED4;
$interaction-blue-50: #0276B1;
$interaction-blue-60: #026597;
$interaction-blue-70: #014E74;
$custom-blue-10: #F0FAFF;
$custom-blue-20: #A9C7D5;
$custom-blue-30: #11354C;

// Yellow
$accent-yellow: #FFF1D0;
$custom-yellow-10: #846003;
$custom-yellow-70: #E8AD11;

// Red
$interaction-red-10: #FFE5E6;
$interaction-red-20: #FF989A;
$interaction-red-30: #FB5053;
$interaction-red-40: #F23639;
$interaction-red-50: #CC2427;
$interaction-red-60: #BA2629;
$interaction-red-70: #A3282B;

// Green
$interaction-green-10: #D9FAD1;
$interaction-green-20: #A1F28C;
$interaction-green-30: #4BC92C;
$interaction-green-40: #30A512;
$interaction-green-50: #25810E;
$interaction-green-60: #1E6A0B;
$interaction-green-70: #185309;
$accent-green: #DAE8D8;

// Text
$text-base-dark: #141414;
$text-base-light: #FFFFFF;
$text-subdued-dark: #3D3D3D;
$text-subdued-light: #696969;
$text-disabled: #7D7D7D;
$text-action: #0276B1;
$text-positive: #1E6A0B;
$text-negative: #CC2427;

// Button
$button-disabled: #F4F4F4;
$button-action-base: #0276B1;
$button-action-hover: #026597;
$button-action-active: #014E74;
$button-positive-base: #25810E;
$button-positive-hover: #1E6A0B;
$button-positive-active: #185309;
$button-negative-base: #CC2427;
$button-negative-hover: #BA2629;
$button-negative-active: #A3282B;
