@import '../../../resources/scss/colors.scss';

.textDropdown {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.lightTextDropdown {
  .textDropdownSelect {
    background-color: $white;
    border: 1px solid $digital-grey-60;
  }
}

.darkTextDropdown {
  .textDropdownSelect {
    background-color: $grey-10;
    border: 1px solid $grey-50;
  }
}

.textDropdownSelect {
  // Select Styles
  display: flex;
  align-items: center;
  align-self: stretch;
  height: 3rem;
  padding: 0.75rem;
  border-radius: 0.25rem;
  box-sizing: border-box;
  cursor: pointer;

  // Text Styles
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  color: $text-base-dark;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  // Icon Styles
  background: url('../../../resources/icons/ChevronDown.svg') 98% / 1.5rem no-repeat $text-base-dark;

  &:active {
    outline: 0.125rem solid $interaction-blue-60;
  }

  &:focus-visible {
    outline: 0.125rem solid $button-action-hover;
  }

  &:disabled {
    background-color: $grey-10;
    outline: 1px solid $digital-grey-15;
  }
}

.option {
  color: white;
  background-color: $digital-grey-60;
  padding: 0 0.625rem;
}
