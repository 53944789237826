@import '../../../resources/scss/z-indexes.scss';

.filterIconFilter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }

  .filterButton {
    display: flex;
    margin-left: 0.25rem;
  }
}

.filterIconFilterClose {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }

  .filterButton {
    display: flex;
    margin-left: 0.25rem;
  }

}

.filterIconTextFilter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    // MANUAL OVERRIDE - removed the margin-left from all breakpoints
  }

  .filterButton {
    display: flex;
    margin-left: 0.25rem;
  }

}

.filterTextIconFilter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .text {
    display: flex;
    margin-right: 0.5rem; // MANUAL OVERRIDE
  }

  .filterButton {
    display: flex;
    margin-left: 0rem; // MANUAL OVERRIDE
    align-self: center; // MANUAL OVERRIDE
    margin-right: 0.25rem; // MANUAL OVERRIDE
  }

  // MANUAL OVERRIDE STARTS
  @media (min-width: 1024px) {
    .text {
      margin-right: 0rem;
    }

    .filterButton {
      margin-left: 0.5rem;
      margin-left: 0.5rem;
      margin-left: 0.5rem;
    }
  }

  // MANUAL OVERRIDE ENDS

}

// MANUAL OVERRIDE STARTS
.flatpickr {
  display: none;
}

.toggle {
  display: flex;
  align-self: stretch;
}

.dropdown {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
}

.dropdownMenu {
  z-index: $dropdown-menu-z-index;
}

.searchInput {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0;
  width: 8.55rem;
  height: 1.5rem;
  margin-left: 0.25rem;
  padding: 0 !important;
  align-self: center; // MANUAL OVERRIDE

  ::placeholder {
    color: rgb(125, 125, 125);
    padding-left: 0.1rem;
    font-size: 0.875rem;
  }

  input {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
  }
}

.searchInput:focus-visible {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0;
  width: 11.5rem;
  height: 1.8125rem;

  ::placeholder {
    color: rgb(125, 125, 125);
    padding-left: 0.1rem;
  }
}

.searchInput:active {
  @extend .searchInput;
}

.closeButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex: 0 0 auto;

  &:nth-child(1) {
    margin-left: 0;
  }
}

.dropdownButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex: 0 0 auto;

  &:nth-child(1) {
    margin-left: 0;
  }
}

// MANUAL OVERRIDE ENDS