// MANUAL OVERRIDE
::placeholder { 
  color: rgb(255, 255, 255);
}
.textInputEmptyDefaultCenterRegular {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(105, 105, 105);
  outline-offset: 0;

  .input {
    display: flex;
    width: unset;
    align-self: stretch;
    text-align: center; // MANUAL OVERRIDE
  }
  
  &:active {
    outline: 2px solid rgb(2, 101, 151);
  }
  &:disabled {
    background-color: rgb(244, 244, 244);
    outline: 1px solid rgb(212, 212, 212);
  
  }
  &:error {
    outline: 2px solid rgb(204, 36, 39);
  
  }
  &:focus-visible {
    outline: 2px dashed rgb(2, 101, 151);
  
  }
}

.textInputEmptyDefaultRegular {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(105, 105, 105);
  outline-offset: 0;
  // MANUAL OVERRIDE STARTS
  ::placeholder { 
    color: rgb(105, 105, 105);
  }
  // MANUAL OVERRIDE ENDS
  .input {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  &:active {
    outline: 2px solid rgb(2, 101, 151);
  
  }
  &:disabled {
    background-color: rgb(244, 244, 244);
    outline: 1px solid rgb(212, 212, 212);
  
  }
  &:error {
    outline: 2px solid rgb(204, 36, 39);
  
  }
  &:focus-visible {
    outline: 2px dashed rgb(2, 101, 151);
  
  }
}

.textInputEmptyDefaultSmall {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(105, 105, 105);
  outline-offset: 0;

  .input {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  &:active {
    outline: 2px solid rgb(2, 101, 151);
  
  }
  &:disabled {
    background-color: rgb(244, 244, 244);
    outline: 1px solid rgb(212, 212, 212);
  
  }
  &:error {
    outline: 2px solid rgb(204, 36, 39);
  
  }
  &:focus-visible {
    outline: 2px dashed rgb(2, 101, 151);
  
  }
}

.textInputFilledDefaultCenterRegular {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(105, 105, 105);
  outline-offset: 0;

  .input {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  &:active {
    outline: 2px solid rgb(2, 101, 151);
  
  }
  &:disabled {
    background-color: rgb(244, 244, 244);
    outline: 1px solid rgb(212, 212, 212);
  
  }
  &:error {
    outline: 2px solid rgb(204, 36, 39);
  
  }
  &:focus-visible {
    outline: 2px dashed rgb(2, 101, 151);
  
  }
}

.textInputFilledDefaultRegular {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(105, 105, 105);
  outline-offset: 0;

  .input {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  &:active {
    outline: 2px solid rgb(2, 101, 151);
  
  }
  &:disabled {
    background-color: rgb(244, 244, 244);
    outline: 1px solid rgb(212, 212, 212);
  
  }
  &:error {
    outline: 2px solid rgb(204, 36, 39);
  
  }
  &:focus-visible {
    outline: 2px dashed rgb(2, 101, 151);
  
  }
}

.textInputFilledDefaultSmall {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(105, 105, 105);
  outline-offset: 0;

  .input {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  &:active {
    outline: 2px solid rgb(2, 101, 151);
  
  }
  &:disabled {
    background-color: rgb(244, 244, 244);
    outline: 1px solid rgb(212, 212, 212);
  
  }
  &:error {
    outline: 2px solid rgb(204, 36, 39);
  
  }
  &:focus-visible {
    outline: 2px dashed rgb(2, 101, 151);
  
  }
}

.textInputEmptyClearRegular {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;

  .input {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    color: rgb(255, 255, 255); // MANUAL OVERRIDE
  }
  
}

.textInputFilledClearRegular {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;

  .input {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    color: rgb(255, 255, 255); // MANUAL OVERRIDE
  }
  
  .button {
    display: flex;
    margin-left: 0.625rem;
  }
  
}
