.searchButton {
  display: flex;
  align-self: center;
  padding: 0.625rem;

  .searchButtonIcon {
    width: 1.25rem;
    height: 1.25rem;
    line-height: 0;
  }
}
