@import '../../../resources/scss/colors.scss';

$border: 1px solid $digital-grey-10;
$max-width-desktop: 55.5rem;

.checkoutPage {
  display: flex;
  align-self: unset !important; // Override main layout styles
  width: 100%;

  // Common styles for sub-components (Start)

  .column {
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .withGap {
    gap: 0.5rem;
  }

  .withWrap {
    flex-wrap: wrap;
  }

  .infoIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
  }

  .textInput {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    padding: 0 0.5rem;
    border-radius: 0.125rem;

    input {
      padding: 0;
      font-size: 0.875rem;
      line-height: 1.3125rem;
      color: $digital-grey-100;

      &::placeholder {
        color: $text-base-dark;
        opacity: 1;
      }
    }

    &.textInputError {
      outline: 1px solid $interaction-red-50;
    }

    &.thickTextInputError {
      outline: 0.125rem solid $interaction-red-50;
    }
  }

  .select {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    padding: 0 0.5rem;
    border-radius: 0.125rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;

    &:active {
      outline: none;
    }

    &.thickSelectError {
      outline: 0.125rem solid $interaction-red-50;
      outline-offset: -1px;
    }
  }

  .actionButton {
    padding: 0.25rem 0.5rem;

    .actionButtonText {
      p {
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $interaction-blue-50;
      }
    }
  }

  .checkbox {
    display: flex;
    gap: 0.5rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: $grey-10;

    .checkboxIcon {
      flex-shrink: 0;
      padding: 0.15625rem;
      margin-top: 0.125rem;
    }
  }

  .errorText {
    margin-top: 0.5rem;
  }

  .boxError {
    background-color: $interaction-red-10;
  }

  .boldText {
    font-weight: 600;
  }

  p.greenText,
  span.greenText {
    color: #008140;
  }

  // Common styles for sub-components (End)
}

.contentContainer {
  flex-grow: 1;
  width: 100%;

  @media (min-width: 1024px) {
    max-width: $max-width-desktop;
    border-left: $border;
    border-right: $border;
    box-sizing: content-box;
    // Set an explicit left margin (not 'auto') so that scrollbar does not shift the content
    // Use 100vw instead of 100% because 100vw is no affected by scrollbar
    margin-left: calc((100vw - #{$max-width-desktop}) / 2); // Half of the remaining screen width
    margin-right: auto;
  }
}

.checkoutStepper {
  position: -webkit-sticky;
  position: sticky;
  top: var(--top-fixed-height); // Account for the height of the top fixed content (TopDisclaimer, TopNav and SignInDisclaimer)
  left: 0;

  &.withBoxShadow {
    box-shadow: 0 0.125rem 0.25rem 0 #00000033;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (min-width: 1024px) {
    flex-direction: row-reverse;
  }

  .leftContent {
    width: 100%;
  }

  .rightContent {
    width: 100%;

    @media (min-width: 1024px) {
      max-width: calc(#{$max-width-desktop} / 2);
      border-left: $border;
    }
  }

  .listingDetailInfo {
    padding-top: 0;
  }
}

.checkoutTotal {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
}
