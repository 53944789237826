@import '../../../resources/scss/colors.scss';

.searchBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 0.75rem 0.5rem 1rem;
  border-radius: 100px;
  background-color: $core-blue-60;
  outline: 1px solid $charcoal-30;
  outline-offset: -1px;
  max-height: 2.125rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    align-self: center;
    line-height: 0;
  }

  .textInput {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
    align-self: center;

    > input {
      caret-color: $white;
      color: $white;
      align-self: center;
      height: 1.125rem; // To avoid larger search bar in Safari
      margin-left: 0;
      font-size: 0.75rem;
      line-height: 1.125rem;
    }

    .clearButton {
      display: flex;
      align-self: center;
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 0.5rem;
      padding: 0;

      > div {
        display: flex;
      }

      &:hover {
        background-color: $core-blue-50;
        border-radius: 100px;
      }
    }
  }

  @media (min-width: 1024px) {
    align-items: center;
    width: 16rem;
  }

  &:hover {
    background-color: $custom-blue-30;
  }

  &:has(input[class*='mouse-focus']:focus) {
    outline: 1px solid $core-blue-40;
  }

  &:has(input[class*='tab-focus']:focus) {
    background-color: $custom-blue-30;
    outline: 1px dashed $white;
  }
}
