.customQuestionsBlock {
  .customTitle {
    .customValue {
      font-size: 1.25rem;
      line-height: 1.5rem;
      color: rgb(68, 70, 73);
    }
  }
}

.bulletList {
  list-style: disc;
  color: rgb(125, 125, 125);
}

.answerParagraph {
  align-self: flex-start;
  flex: 1 1 auto;
  font-family: 'Optimist', 'sans-serif';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  color: rgb(61, 61, 61);
  margin-bottom: 1rem;
}

.linkButton {
  color: rgb(13, 110, 253); // This is to make button look like link.
}

.tabList {
  display: none;

  @media (min-width: 1024px) {
    overflow: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    margin-top: 1.5rem;
    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
}

.contactUs {
  margin-top: 1.5rem;
}
