@import '../../../resources/scss/colors.scss';

.listingDetailInfo {
  gap: 1rem;
  padding: 1rem;
}

.seatingInfo {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.75rem;
  border-radius: 0.5rem;
  background-color: $grey-10;

  .availableWidthItem {
    flex-grow: 1;
  }

  .fixedWidthItem {
    min-width: 3.75rem;
  }
}

.capitalOneBottomLogo {
  width: 6.625rem;
  height: 1.6875rem;
  margin-left: -0.75rem;
}

.poweredByVividSeatsBottomLogo {
  width: 7.375rem;
  height: 1.875rem;
}
