@import '../../../resources/scss/colors.scss';

.emptyState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: 0.5rem;
  height: 23.25rem;
  margin: 1rem;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.25rem;
  // Apply 100ms delay before showing the component
  // This is required to give enough time for any state updates
  opacity: 0;
  visibility: hidden;
  animation: fadeIn 0.1s ease-in-out forwards 0.1s;

  @media (min-width: 768px) {
    margin: 1.5rem;
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
      visibility: visible;
    }
  }
}

.lightEmptyState {
  background-color: $grey-10;
}

.darkEmptyState {
  background-color: $core-blue-60;
}
