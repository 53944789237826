@import '../../../resources/scss/colors.scss';

.searchFieldContainer {
  width: 100%;
  padding: 1.25rem 1.5rem calc(1.25rem - 1px) 1.5rem; // Account for 1px for bottom border
  border-bottom: 1px solid $digital-grey-5;
  box-sizing: border-box;

  .searchField {
    width: 100%;
    padding: 0;

    input {
      align-self: stretch;
      padding: unset;
      margin-left: 0.75rem;

      &::placeholder {
        color: $digital-grey-50;
      }
    }
  }
}