.submenuWithoutTabs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);
  width: 100%;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0; // MANUAL OVERRIDE
    width: 100%;
  }

  .submenuItemList {
    a {
      // Layout contains 4 columns
      width: calc((100% - 3rem) / 4); // Account for 4 x 0.75rem for gaps
    }
  }

  .boldText {
    p {
      font-weight: 600;
    }
  }

  @media (min-width: 1440px) {
    .submenuItemList {
      a {
        // Layout contains 4 columns
        width: calc((100% - 2.5rem) / 4); // Account for 4 x 0.625rem for gaps
      }
    }
  }

}
