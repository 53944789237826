.flatpickr-input {
  display: none;
}

.flatpickr-calendar.rangeMode.inline {
  top: 0;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange {
  background-color: rgb(2, 101, 151) !important;
}
