@import '../../../resources/scss/colors.scss';
@import '../../../resources/scss/z-indexes.scss';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 !important; // Override inline styles for padding that are applied by modal
  backdrop-filter: blur(0.625rem);
  z-index: $modal-z-index;
  background: #FFFFFFE5;

  @media (min-width: 1024px) {
    backdrop-filter: unset;
    background: #00000066;
  }

  .modalDialog {
    width: 100%;
    height: 100%;

    @media (min-width: 1024px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: unset;
      height: unset;
    }
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: 1024px) {
      background: $white;
      width: 32rem;
      max-height: 100vh;
      border-radius: 0.625rem;
      outline: 1px solid $grey-30;
    }
  }

  .changeConfirmationModalContent {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 37.8125rem;
    padding: 0.75rem 1rem 2rem 1rem;

    @media (min-width: 1024px) {
      gap: 1.5rem;
      padding: 2rem;
    }
  }

  .changeConfirmationModalHeader {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 1024px) {
      justify-content: space-between;
    }
  }

  .closeButton {
    padding: 0;
    background-color: transparent;

    &:hover {
      border-radius: 0.25rem;
      background-color: $custom-blue-10;
    }

    &:focus-visible {
      border-radius: 0.25rem;
      background-color: $custom-blue-10;
      outline: 1px dashed $interaction-blue-50;
      outline-offset: -1px;
    }

    &:active {
      border-radius: 0.25rem;
      background-color: $interaction-blue-10;
    }

    .closeButtonIcon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    gap: 0.5rem;
    margin-top: 1rem;

    @media (min-width: 1024px) {
      margin-top: 0;
    }

    .primaryButton {
      width: 100%;
      height: auto;

      .primaryButtonLabel {
        h2 {
          font-size: 1rem;
        }
      }

      &:focus-visible {
        outline: 0.125rem dashed #FF9900;
        outline-offset: 0;
      }

    }

    .secondaryButton {
      display: flex;
      align-self: center;

      &:focus-visible {
        outline: 0.125rem dashed #FF9900;
        outline-offset: 0;
      }
    }
  }
}