.infoOnMultipleLine {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .label {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .buyerName {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .address {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .cityProvinceCountry {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .postalCode {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
}
