@import '../../../resources/scss/colors.scss';

$border-radius: 0.125rem;

.quantityContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  .text p {
    line-height: 1.125rem;
  }
}

.quantityWrapper {
  display: flex;
  align-items: center;
  gap: 0.4375rem;

  .quantity {
    min-width: 1.5rem;
  }

  .button {
    width: 1.375rem;
    height: 1.375rem;
    padding: 0;

    &:disabled {
      background-color: $button-disabled;
      border-radius: $border-radius;
      border: 1px solid #E7E9E9;

      .buttonIcon path {
        fill: #6C7275;
      }
    }

    &:hover:not(:disabled) {
      border-radius: $border-radius;
      background-color: $custom-blue-10;
    }

    &:focus-visible:not(:disabled) {
      border-radius: $border-radius;
      background-color: $custom-blue-10;
      outline: 1px dashed $interaction-blue-50;
      outline-offset: -1px;
    }

    &:active:not(:disabled) {
      border-radius: $border-radius;
      background-color: $interaction-blue-10;
    }

    .buttonIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 0.875rem;
      height: 1rem;

      path {
        fill: #444649;
      }
    }
  }
}
