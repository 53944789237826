@import '../../../resources/scss/colors.scss';

$outline-width: 0.125rem;
$outline: $outline-width dashed $interaction-blue-50;

.eventCard {
  display: flex;
  width: 100%;
  background-color: $white;
}

.payWithRewardsOnlyBadge {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  width: fit-content;
}

.horizontalEventCard {
  $padding-y: 0.75rem;

  padding: $padding-y 0.75rem 0 0.75rem;

  @media (min-width: 1024px) {
    padding: $padding-y 1rem 0 1rem;
  }

  &.noEventImage {
    min-height: 6.875rem;
  }

  &:hover {
    background-color: $custom-blue-10;
  }

  &:focus-visible {
    background-color: $custom-blue-10;
    outline: $outline;
    outline-offset: -$outline-width;
  }

  &:active {
    background-color: $interaction-blue-10;
  }

  &:last-child {
    .eventCardInnerContainer {
      padding: 0 0 $padding-y 0;
      border-bottom: none;
    }
  }

  .eventCardInnerContainer {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
    padding: 0 0 calc(#{$padding-y} - 1px) 0; // Account for 1px for bottom border
    border-bottom: 1px solid $digital-grey-10;
    box-sizing: border-box;
  }

  .eventImage {
    display: flex;
    flex-shrink: 0;
    width: 5.125rem;
    height: 5.1875rem;
    border-radius: 0.125rem;

    @media (min-width: 1024px) {
      width: 9.75rem;
      height: 6.5rem;
    }
  }

  .eventContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    gap: 0.75rem;
    overflow-x: hidden;
  }

  .eventLeftSubContent {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
    overflow-x: hidden;
  }

  .eventStatusBadge {
    padding-top: 0.25rem;
  }

  .payWithRewardsOnlyBadge {
    padding: 0.125rem;
  }

  .eventRightSubContent {
    display: none;

    @media (min-width: 1024px) {
      display: flex;
      flex-shrink: 0;
    }
  }
}

.squareOnLightEventCard,
.squareOnDarkEventCard {
  $border-radius: 0.75rem;

  flex-direction: column;
  border-radius: $border-radius;
  box-shadow: 0 0.125rem 0.25rem 0 #00000033;
  position: relative;

  .topContent {
    display: flex;
    min-height: 10.625rem;
    border-radius: $border-radius $border-radius 0 0;
    position: relative;
    overflow: hidden;
  }

  // If event card is rendered against dark background (e.g. Cardholder Exclusives section on Home page) and event image is present,
  // then in some cases there may be an issue whereby some white pixels show up around rounded corners of the event card.
  // This is caused by pixelisation / resolution of the rounded corners.
  // This is most noticeable when event image is also dark.
  // We apply dark background colour to event card to override white colour in this case.
  &.squareOnDarkEventCard:has(.eventImage) {
    background-color: $core-blue-70;
  }

  .eventImage {
    position: relative;
    transition: transform 0.3s ease;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(246.2deg, rgba(0, 66, 106, 0.75) 0%, rgba(0, 19, 43, 0) 51.56%);
    }
  }

  // Scale event image up/down on hover in/out
  &:hover {
    .eventImage {
      transform: scale(1.2);
    }
  }

  .cardholderExclusiveBadge {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    max-width: calc(100% - 1.25rem); // To account for the left padding (0.5rem) and right padding (0.75rem)
  }

  .payWithRewardsOnlyBadge {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $interaction-blue-10;
    padding: 0.375rem 0.5rem 0.375rem 0.375rem;
    border-top-right-radius: 0.25rem;
    box-shadow: 0 0 1px 0 #00000014;
  }

  .bottomContent {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 1rem;
    background-color: $white;
    height: 100%;
    border-radius: 0 0 $border-radius $border-radius;
    box-sizing: border-box;

    p {
      display: -webkit-box !important;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      line-clamp: 2;
    }
  }

  .eventDescription {
    padding-bottom: 0.3125rem;
  }

  .overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: $border-radius;
    outline: $outline;
    outline-offset: -$outline-width;
  }

  &:focus-visible {
    outline: none;

    .overlay {
      display: block;
    }
  }
}