.thumbnailCardList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;

  .thumbnailCard {
    display: flex;
    width: 8.75rem;
    height: 8.75rem;
  }
  
  @media (min-width: 1024px) {
    flex-direction: row;
    align-content: flex-start;

    // MANUAL OVERRIDE - removed calc width function for .thumbnailCard
    
  }
}
