@import '../../../resources/scss/colors.scss';

.listingsFilters {
  display: flex;
  flex-direction: column;
}

.listingsFilter {
  padding: 1rem 1rem calc(1rem - 1px) 1rem; // Account for 1px for bottom border
  border-bottom: 1px solid $digital-grey-10;

  &:last-child {
    padding-bottom: 1rem;
    border-bottom: none;
  }
}
