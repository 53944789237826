@import '../../../resources/scss/colors.scss';

.mobileSearchHeader {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0.75rem 0.5rem calc(0.75rem - 1px) 0.5rem; // Account for 1px for bottom border
  border-bottom: 1px solid $core-blue-60;
  background-color: $core-blue-70;
  box-sizing: border-box;

  .backButton {
    display: flex;
    background-color: transparent;
    padding: 0.5rem 0;
  }

  .backButtonIcon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .searchBar {
    display: flex;
    width: 100%;
    max-height: 2.125rem;
    flex: 1 1 auto;
  }
}
