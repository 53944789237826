.seatInfo {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.5rem 1.5rem;
  flex-wrap: wrap;

  .section {
    display: flex;
    min-width: 4.5rem;
  }

  .row {
    display: flex;
    min-width: 1.5rem;
  }

  .quantity {
    display: flex;
    min-width: 1.25rem;
  }

  .seats {
    display: flex;
    min-width: 1.875rem;
  }
}
