@import '../../../resources/scss/colors.scss';

.radioButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  gap: 0.5rem;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
  }
}

.radioButtonLabel {
  display: flex;
  align-items: center;

  &.darkDisabled {
    p {
      color: $grey-80;
    }
  }
}

.radioButtonIcon {
  flex-shrink: 0;

  &.disabledIcon {
    opacity: 0.5;
  }
}
