@import '../../../resources/scss/colors.scss';
@import '../../../resources/scss/z-indexes.scss';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 !important; // Override inline styles for padding that are applied by modal
  background: #00132BCC;
  backdrop-filter: blur(0.625rem);
  z-index: $modal-z-index;
}

.modalDialog {
  width: 100%;
  height: 100%;

  @media (min-width: 1024px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: unset;
    height: unset;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: $core-blue-70;
  position: relative;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1024px) {
    width: 44.0625rem;
    max-height: 100vh;
    outline: 1px solid #FFFFFF33;
    border-radius: 0.75rem;
  }

  .topContent {
    padding: 2rem 1rem 1rem 1rem;

    @media (min-width: 1024px) {
      padding: 2rem 2rem 1rem 2rem;
    }
  }

  .bottomContent {
    padding: calc(1rem - 1px) 1rem 1rem 1rem; // Account for 1px for top border

    @media (min-width: 1024px) {
      padding: calc(2rem - 1px) 2rem 2rem 2rem; // Account for 1px for top border
    }
  }
}
