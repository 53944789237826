@import '../../../resources/scss/z-indexes.scss';

.skipToMainContent {
  position: absolute;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: -webkit-transform 0s;
  transition: -webkit-transform 0s;
  transition: transform 0s;
  transition: transform 0s, -webkit-transform 0s;
  z-index: $skip-to-content-z-index;
}

.skipToMainContent:active,
.skipToMainContent:focus {
  -webkit-transform: translateY(0.0625rem);
  transform: translateY(0.0625rem);
}
