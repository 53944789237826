@import '../../../resources/scss/colors.scss';

.carouselButton {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0.75rem;
  border-radius: 6.25rem;
  background-color: $white;
  box-shadow: 0 0.125rem 0.5rem 0 #00000033;
  // This z-index must be greater than z-index of Carousel's .carouselItem class
  z-index: 3;

  &:focus-visible {
    outline: 0.125rem dashed $interaction-blue-50;
    outline-offset: -0.125rem;
  }
}

.backwardCarouselButton {
  left: -1.5rem;
}

.forwardCarouselButton {
  right: -1.5rem;
}

.carouselButtonIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.1875rem;
}
