@import '../../../resources/scss/colors.scss';

$border-radius: 6.25rem;
$selection-chip-z-index: 0;

.multiToggle {
  display: flex;
  position: relative;
  background-color: $grey-10;
  border-radius: $border-radius;
}

.multiToggleItem {
  display: flex;
  padding: 0.25rem 1rem;
  border-radius: $border-radius;
  z-index: calc(#{$selection-chip-z-index} + 1); // Ensure that the sliding selection chip is below multi togle items

  &:focus-visible {
    outline: 0.125rem dashed $interaction-blue-50;
    outline-offset: -0.125rem;
  }

  &.selectedMultiToggleItem {
    color: $white;

    &:focus-visible {
      outline: 0.125rem dashed $white;
    }
  }
}

.selectionChip {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: $interaction-blue-50;
  border-radius: $border-radius;
  z-index: $selection-chip-z-index; // Ensure that the sliding selection chip is below multi togle items

  &.animatedSelectionChip {
    transition: left 0.3s ease, width 0.3s ease;
  }
}
