.emptyStateBanner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 2rem 3rem 2rem;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(229, 229, 229);
  outline-offset: 0;

  .icon {
    display: block;
    width: 5rem;
    height: 5rem;
  }

  // MANUAL OVERRIDE STARTS
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    >* {
      margin-top: 1rem;
    }

    align-self: stretch;
    flex: 0 0 auto;
  }

  // MANUAL OVERRIDE ENDS
  .text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    // MANUAL OVERRIDE STARTS
    >* {
      margin-top: 0.5rem;
    }

    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    // MANUAL OVERRIDE ENDS
  }

  .title {
    display: flex;
    // MANUAL OVERRIDE STARTS
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    // MANUAL OVERRIDE ENDS
  }

  .message {
    display: flex;
    // MANUAL OVERRIDE STARTS
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    // MANUAL OVERRIDE ENDS
  }

  .button {
    display: flex;
    // MANUAL OVERRIDE STARTS
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    // MANUAL OVERRIDE ENDS
  }

}

@media (min-width: 1024px) {

  // MANUAL OVERRIDE STARTS
  .emptyStateBanner {
    padding: 2rem 2rem 2rem 2rem;

    >* {
      margin-top: 1rem;
    }

    border-radius: 4px;

    .icon {
      overflow: hidden;
      align-self: center;
      width: 3rem;
      height: 3rem;
      flex: 0 0 3rem;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .text {
      justify-content: center;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .button {

      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  // MANUAL OVERRIDE ENDS
}