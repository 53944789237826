@import '../../../resources/scss/colors.scss';

$border-radius: 0.75rem;
$padding: 1rem;

.soldOutCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius;
  background-color: $white;
  box-shadow: 0 1px 0.25rem 0 #00000033;

  .topContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    gap: 0.5rem;
    padding: $padding $padding calc(#{$padding} - 1px) $padding; // Account for 1px for bottom border
    border-bottom: 1px solid $grey-40;
  }

  .link {
    display: flex;
    align-self: stretch;
    padding: $padding;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    &:hover {
      background-color: $custom-blue-10;
    }

    &:focus-visible {
      background-color: $custom-blue-10;
      outline: 0.125rem dashed $interaction-blue-50;
      outline-offset: -0.125rem;
    }

    &:active {
      background-color: $interaction-blue-10;
    }
  }

  .linkContent {
    display: flex;
    flex-grow: 1;
    gap: 0.625rem;
  }

  .linkText {
    display: flex;
    justify-content: center;
    flex-grow: 1;

    p {
      text-align: center;
    }
  }

  .linkIcon {
    display: flex;
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
  }
}
