@import '../../../resources/scss/colors.scss';

.menuButtonDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(16, 37, 63);

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 1.25rem; // MANUAL OVERRIDE
  }

  .capitalOneCard {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .cardImage {
    display: flex;
    width: 1.25rem;
    height: 0.875rem;
  }

  .icon {
    display: flex; // MANUAL OVERRIDE
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }

  &:active {
    background-color: rgb(62, 75, 89);

  }

  &:focus-visible {
    background-color: rgb(28, 43, 57);
    outline: 2px dashed rgb(255, 255, 255);
    outline-offset: 0;

  }

  &:hover {
    background-color: rgb(28, 43, 57);

  }
}

.menuButtonIconOnly {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: transparent;

  &:active,
  &:hover {
    border-radius: 0.25rem;
    background-color: #ffffff33;
  }

  &:focus-visible {
    border-radius: 0.25rem;
    outline: 1px dashed $white;
    outline-offset: -1px;
  }

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
}
