.contactUsItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .message {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .button {
    display: flex;
    margin-top: 1rem;
  }
  
}
