@import '../../../resources/scss/colors.scss';

$padding-y: 0.75rem;

.submenuWithDoubleTabs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: $white;
  align-self: stretch;
  width: 100vw;
  padding: $padding-y 2rem calc(#{$padding-y} - 1px); // Account for 1px for bottom border
  box-sizing: border-box;
  border-bottom: 1px solid $grey-10;

  @media (min-width: 1440px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    width: 100%;
    max-width: 84rem;
    margin-left: 0;
  }

  .leftContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 0 0 auto;
  }

  .sportsMenuTabList {
    display: flex;
    flex: 1 1 auto;
  }

  .dividerOne {
    display: flex;
    width: 0.0625rem;
    height: unset;
    align-self: stretch;
  }

  .middleContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0;
    width: 12.5rem;
  }

  .sportsMenuTabList1 {
    display: flex;
    flex: 1 1 auto;
  }

  .rightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
  }

  .submenuItemListOne {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }

  .submenuItemListTwo {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }

  .spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(24.625rem - 2 * #{$padding-y}); // Account for 2 x 0.75rem for top and bottom paddings
  }
}
