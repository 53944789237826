@import '../../../resources/scss/colors.scss';

.searchSuggestionItem {
  display: flex;
  padding: 0.75rem 0.75rem 0 0.75rem;

  &:hover {
    background-color: $custom-blue-10;
  }

  &:focus-visible {
    background-color: $custom-blue-10;
    outline: 1px dashed $interaction-blue-50;
    outline-offset: -1px;
  }

  &:active {
    background-color: $interaction-blue-10;
  }
}

.content {
  display: flex;
  padding: 0 0 calc(0.75rem - 1px) 0; // Account for 1px for bottom border
  border-bottom: 1px solid $digital-grey-10;
  width: 100%;
}

.innerContent {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  padding: 0 0 0 0.5rem;
  width: 100%;
  box-sizing: border-box;
}

.truncatable {
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
