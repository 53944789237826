.navMenuItemBack {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    width: unset;
    align-self: stretch;
  }

  .backButton {
    display: flex;
  }

  .backButtonIcon {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem;
    line-height: 0;
  }

  .title {
    display: flex;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
    align-self: center;
    min-height: 2.5rem;
  }
}

.navMenuItemCritical {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    width: unset;
    align-self: stretch;
  }

  .title {
    display: flex;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    align-self: center;
    min-height: 2.5rem;
  }

  @media (min-width: 1024px) {
    .content {
      padding: 0.75rem 1.5rem 0.75rem 1.5rem;
    }
  }
}

.navMenuItemDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    width: unset;
    align-self: stretch;
  }

  .title {
    display: flex;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    align-self: center;
    min-height: 2.5rem;
  }

  @media (min-width: 1024px) {
    .content {
      padding: 0.75rem 1.5rem 0.75rem 1.5rem;
    }
  }
}

.navMenuItemNext {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    width: unset;
    align-self: stretch;
  }

  .title {
    display: flex;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    align-self: center;
    min-height: 2.5rem;
  }

  .nextButton {
    display: flex;
    margin-left: 0.5rem;
  }

  .nextButtonIcon {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem;
    line-height: 0;
  }
}

.navMenuItemCollapsed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    width: unset;
    align-self: stretch;
  }

  .title {
    display: flex;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    align-self: center;
    min-height: 2.5rem;
  }

  .nextButton {
    display: flex;
    margin-left: 0.5rem;
  }

  .nextButtonIcon {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 0;
  }

  @media (min-width: 1024px) {
    .content {
      padding: 0.75rem 1.5rem 0.75rem 1.5rem;
    }
  }

}

.navMenuItemExpanded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    width: unset;
    align-self: stretch;
  }

  .title {
    display: flex;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    align-self: center;
    min-height: 2.5rem;
  }

  .nextButton {
    display: flex;
    margin-left: 0.5rem;
  }

  .nextButtonIcon {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 0;
  }

  @media (min-width: 1024px) {
    .content {
      padding: 0.75rem 1.5rem 0.75rem 1.5rem;
    }
  }
}
