@import '../../../resources/scss/colors.scss';

$border: 1px solid $digital-grey-10;
$border-radius: 0.5rem;

.topContent {
  gap: 0.5rem;
  padding: 1rem 1rem calc(1rem - 1px) 1rem; // Account for 1px for bottom border
  border-bottom: $border;

  .paymentInfo {
    p {
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  }
}

.rewardPaymentOptions {
  border-radius: $border-radius;
  background-color: $grey-10;

  .radioButton {
    gap: 0.75rem;
    padding: 1rem;

    &.applyRewardsRadioButton {
      padding-bottom: calc(1rem - 1px); // Account for 1px for bottom border
      border-bottom: 1px solid $grey-30;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    &.doNotApplyRewardsRadioButton {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }

  .radioButtonContent {
    gap: 0.75rem;
    padding: 0 0.75rem 0 0;

    .applyRewardsRadioButtonContent {
      row-gap: 0.5rem;
    }
  }

  .radioButtonText {
    line-height: 1.125rem;
  }

  .boldText {
    font-weight: 600;
    color: $text-base-dark;
  }

  .verticalDivider {
    height: 1.1875rem;
  }

  .rewardsTextInput {
    width: 3.5625rem;
  }
}

.filter {
  max-width: none;
}

.filterToggleWrapper {
  max-width: none;
  border-radius: 0.5rem;
  height: 3rem;
}

.dropdownMenuBase {
  max-width: none;
  width: calc(100% - 2rem);

  @media (min-width: 1024px) {
    width: calc(27.75rem - 2rem);
  }

  button {
    p {
      font-size: 14px !important;
    }
  }

}

.cardTypeFilter {
  padding: 16px;
}

.formattedRewardUnitsTotal {
  font-weight: 600;
}

.noWrapContainer {
  display: flex;
  white-space: nowrap;
  align-items: center;
  gap: 0.25rem;
}