@import '../../../resources/scss/colors.scss';

.textInputEmptyClearWithIconRegular {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.25rem 1.5rem 1.25rem 1.5rem;
  background-color: rgb(255, 255, 255);

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }

  .input {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }

  &:disabled {
    background-color: rgb(244, 244, 244);

  }

  &:error {
    outline: 2px solid rgb(204, 36, 39);
    outline-offset: 0;

  }

  &:focus-visible {
    outline: 2px dashed rgb(2, 118, 177);
    outline-offset: 0;

  }
}

.textInputEmptyDefaultCenterRegular {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(105, 105, 105);
  outline-offset: 0;

  .input {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  // MANUAL OVERRIDE STARTS
  .icon {
    display: none;
  }

  // MANUAL OVERRIDE ENDS
  &:active {
    outline: 2px solid rgb(2, 101, 151);

  }

  &:disabled {
    background-color: rgb(244, 244, 244);
    outline: 1px solid rgb(212, 212, 212);

  }

  &:error {
    outline: 2px solid rgb(204, 36, 39);

  }

  &:focus-visible {
    outline: 2px dashed rgb(2, 101, 151);

  }
}

.textInputEmptyDefaultRegular {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(105, 105, 105);
  outline-offset: 0;

  .input {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  // MANUAL OVERRIDE STARTS
  .icon {
    display: none;
  }

  // MANUAL OVERRIDE ENDS

  // MANUAL OVERRIDE
  ::placeholder {
    color: rgb(105, 105, 105);
  }

  &:active {
    outline: 2px solid rgb(2, 101, 151);

  }

  &:disabled {
    background-color: rgb(244, 244, 244);
    outline: 1px solid rgb(212, 212, 212);

  }

  &:error {
    outline: 2px solid rgb(204, 36, 39);

  }

  &:focus-visible {
    outline: 2px dashed rgb(2, 101, 151);

  }
}

.textInputEmptyDefaultSmall {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(105, 105, 105);
  outline-offset: 0;

  .input {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  // MANUAL OVERRIDE STARTS
  .icon {
    display: none;
  }

  // MANUAL OVERRIDE ENDS
  &:active {
    outline: 2px solid rgb(2, 101, 151);

  }

  &:disabled {
    background-color: rgb(244, 244, 244);
    outline: 1px solid rgb(212, 212, 212);

  }

  &:error {
    outline: 2px solid rgb(204, 36, 39);

  }

  &:focus-visible {
    outline: 2px dashed rgb(2, 101, 151);

  }
}

.textInputFilledDefaultCenterRegular {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(105, 105, 105);
  outline-offset: 0;

  .input {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  &:active {
    outline: 2px solid rgb(2, 101, 151);

  }

  &:disabled {
    background-color: rgb(244, 244, 244);
    outline: 1px solid rgb(212, 212, 212);

  }

  &:error {
    outline: 2px solid rgb(204, 36, 39);

  }

  &:focus-visible {
    outline: 2px dashed rgb(2, 101, 151);

  }
}

.textInputFilledDefaultRegular {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(105, 105, 105);
  outline-offset: 0;

  .input {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  &:active {
    outline: 2px solid rgb(2, 101, 151);

  }

  &:disabled {
    background-color: rgb(244, 244, 244);
    outline: 1px solid rgb(212, 212, 212);

  }

  &:error {
    outline: 2px solid rgb(204, 36, 39);

  }

  &:focus-visible {
    outline: 2px dashed rgb(2, 101, 151);

  }
}

.textInputFilledDefaultSmall {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(105, 105, 105);
  outline-offset: 0;

  .input {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  &:active {
    outline: 2px solid rgb(2, 101, 151);

  }

  &:disabled {
    background-color: rgb(244, 244, 244);
    outline: 1px solid rgb(212, 212, 212);

  }

  &:error {
    outline: 2px solid rgb(204, 36, 39);

  }

  &:focus-visible {
    outline: 2px dashed rgb(2, 101, 151);

  }
}

.textInputEmptyClearRegular {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;

  .input {
    display: flex;
    width: unset;
    flex: 1 1 auto;

    &::placeholder {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.3125rem;
      font-family: 'Optimist', sans-serif;
      color: $charcoal-20;
    }
  }

  .icon {
    display: none;
  }
}

.textInputFilledClearRegular {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;

  .input {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }

  .button {
    display: flex;
    margin-left: 0.625rem;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }

}
