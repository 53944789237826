.svgMapContainer {
  width: 100%;
  height: 100%;
  cursor: default !important; // Override inline styles for panzoom's "move" pointer
}

.svgMap {
  width: 100%;
  height: 100%;
  cursor: pointer;
  pointer-events: visiblefill;

  &.svgMapWithPanZoom:active {
    cursor: grabbing;
  }
}
