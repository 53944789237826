.dividerHorizontalThickWhite {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);
  height: 0.1875rem; // MANUAL OVERRIDE

}

.dividerHorizontalThinCP1Red {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch; // MANUAL OVERRIDE
  height: 0.0625rem; // MANUAL OVERRIDE
  background-color: rgb(205, 34, 40);

}

.dividerHorizontalThinDigitalGrey10 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch; // MANUAL OVERRIDE
  height: 0.0625rem; // MANUAL OVERRIDE
  background-color: rgb(229, 229, 229);

}

.dividerHorizontalThinDigitalGrey15 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(212, 212, 212);
  height: 0.0625rem; // MANUAL OVERRIDE
  align-self: stretch; // MANUAL OVERRIDE
}

.dividerHorizontalThinDigitalGrey5 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(244, 244, 244);
  align-self: stretch; // MANUAL OVERRIDE
  height: 0.0625rem; // MANUAL OVERRIDE
}

.dividerHorizontalThinWhite {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);
  align-self: stretch; // MANUAL OVERRIDE
  height: 0.0625rem; // MANUAL OVERRIDE
}

.dividerVerticalThickCP1Red {
  background-color: rgb(204, 36, 39);
  width: 0.25rem;
  height: 4.125rem;
  // MANUAL OVERRIDE STARTS
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  // MANUAL OVERRIDE ENDS
}

.dividerVerticalThickClear {
  background-color: rgb(255, 255, 255);
  width: 0.25rem;
  height: 4.125rem;
  // MANUAL OVERRIDE STARTS
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  // MANUAL OVERRIDE ENDS
}

.dividerVerticalThickInteractionBlue50 {
  background-color: rgb(2, 118, 177);
  width: 0.25rem;
  height: 4.125rem;
  // MANUAL OVERRIDE STARTS
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  // MANUAL OVERRIDE ENDS
}

.dividerVerticalThickWhite {
  background-color: rgb(255, 255, 255);
  width: 0.25rem;
  height: 4.125rem;
  // MANUAL OVERRIDE STARTS
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  // MANUAL OVERRIDE ENDS

}

.dividerVerticalThinDigitalGrey10 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(229, 229, 229);
  width: 0.0625rem;
  height: 2rem;
  align-self: stretch; // MANUAL OVERRIDE
  flex: 0 0 auto; // MANUAL OVERRIDE

}

.dividerVerticalThinDigitalGrey15 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(212, 212, 212);
  width: 0.0625rem;
  // height: 2rem; //MANUAL OVERRIDE
  align-self: stretch; // MANUAL OVERRIDE
  flex: 0 0 auto; // MANUAL OVERRIDE

}

.dividerVerticalThinWhite {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);
  width: 0.0625rem;
  height: 2rem;
  align-self: stretch; // MANUAL OVERRIDE
  flex: 0 0 auto; // MANUAL OVERRIDE

}
