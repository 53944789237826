.paymentMethod {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .label {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .capitalOneCard {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
    gap: 0.25rem; // MANUAL OVERRIDE
  }
  
  .cardImage {
    display: flex;
    width: 1.5rem;
    height: 1rem;
  }
  
  .description {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    // MANUAL OVERRIDE MARGIN LEFT REMOVED TO FIX UI FOR WITHOUT CARD
  }
  
}
