@import '../../../resources/scss/colors.scss';
@import '../../../resources/scss/z-indexes.scss';

$outer-padding: 1rem;
$inner-padding: 1.5rem;
$image-size-mobile: 4.5rem;
$image-size-desktop: 21.6875rem;

.modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: $outer-padding !important; // Override inline styles for padding that are applied by modal
  box-sizing: border-box;
  background: #00000040;
  z-index: $modal-z-index;

  .modalDialog {
    display: flex;
    max-height: calc(100vh - 2 * #{$outer-padding}); // Account for 2 x 1rem for outer paddings
    padding: 0 $inner-padding;
    background-color: $white;
    box-sizing: border-box;
    border-radius: 0.25rem;

    @media (min-width: 1024px) {
      max-width: 62rem;
    }
  }

  .modalContent {
    display: flex;

    @media (min-width: 1024px) {
      max-height: 37.5rem;
    }
  }

  .lineHeight21px p {
    line-height: 1.3125rem;
  }

  .lineHeight31px h1 {
    line-height: 1.9375rem;
  }

  .row {
    display: flex;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .gap24px {
    gap: 1.5rem;
  }

  .gap32px {
    gap: 2rem;
  }

  .fullWidth {
    width: 100%;
  }

  .desktopOnly {
    display: none;

    @media (min-width: 1024px) {
      display: flex;
      padding-top: $inner-padding;
      padding-bottom: $inner-padding;
    }
  }

  .mobileOnly {
    display: flex;

    @media (min-width: 1024px) {
      display: none;
    }
  }

  .eventInfoModalContent {
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .eventInfoHeader {
    justify-content: space-between;
    gap: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: sticky;
    top: 0;
    background: #FFFFFFE5;

    @media (min-width: 1024px) {
      padding-top: 1.875rem;
      padding-bottom: 0.375rem;
    }

    .closeButton {
      width: 1.5rem;
      height: 1.5rem;
      background-color: transparent;
      margin-top: 0.375rem;

      &:hover {
        border-radius: 0.25rem;
        background-color: $custom-blue-10;
      }

      &:focus-visible {
        border-radius: 0.25rem;
        background-color: $custom-blue-10;
        outline: 1px dashed $interaction-blue-50;
        outline-offset: -1px;
      }

      &:active {
        border-radius: 0.25rem;
        background-color: $interaction-blue-10;
      }
    }
  }

  .eventImage {
    display: flex;
    align-self: center;
    width: $image-size-mobile;
    max-height: $image-size-mobile;

    @media (min-width: 1024px) {
      width: $image-size-desktop;
      max-height: unset;
    }
  }

  // CSS that is used to style content's HTML
  .eventDescription {
    padding-bottom: $inner-padding;
    overflow-wrap: anywhere;

    ul li {
      list-style: disc;
    }
  }
}
