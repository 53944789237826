@import '../../../resources/scss/colors.scss';

.sliderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 2rem;

  .formattedValue {
    min-width: 3.75rem;

    p {
      line-height: 1.125rem;
    }
  }
}

.sliderWrapper {
  display: flex;
  flex: 1 1 auto;

  .slider {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    height: 2rem;

    // Part of the track between left and right thumbs
    :nth-child(2) {
      background: $core-blue-40;
    }
  }

  .track {
    height: 0.375rem;
    background: $digital-grey-15;
    border: none;
    border-radius: 0.375rem;
    cursor: default;
  }

  .thumb {
    width: 1.5rem;
    height: 1.5rem;
    background: $white;
    box-shadow: 0 0.125rem 0.25rem 0 #0000004D;
    border: 1px solid #E7E9E9;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      background-color: $custom-blue-10;
    }

    &:focus-visible {
      outline: none;
    }

    &:active {
      background-color: $interaction-blue-10;
    }
  }
}
