.submenuWithTabs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);
  width: 100%;
  margin-left: 0.75rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .leftContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 0 0 auto;
  }

  .sportsMenuTabList {
    display: flex;
    // width: unset;  // MANUAL OVERRIDE
    flex: 1 1 auto;
  }

  .divider1 {
    display: flex;
    width: 0.0625rem;
    height: unset;
    align-self: stretch;
  }

  .rightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex: 1 1 auto;
  }

  .submenuItemList {
    a {
      // Layout contains 3 columns
      width: calc((100% - 2.25rem) / 3); // Account for 3 x 0.75rem for gaps
    }
  }

  .boldText {
    p {
      font-weight: 600;
    }
  }

  @media (min-width: 1440px) {
    margin-left: 0.625rem;

    .submenuItemList {
      a {
        // Layout contains 3 columns
        width: calc((100% - 1.875rem) / 3); // Account for 3 x 0.625rem for gaps
      }
    }
  }
}
