.hideErrorButton {
  display: none;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.errorIcon {
  background-size: contain;
  background-position: center;
}

.notAccessibleErrorBlockTitle {
  display: flex;
}

.errorBlock {
  height: unset;
  flex: 1 1 auto;
}

@media (min-width: 1024px) {
  .notAccessibleErrorBlockTitle {
    width: 21.063rem;
    flex: 0 0 auto;
  }
}
