.eventsCardList {
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap; // MANUAL OVERRIDE
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;

  .card {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  @media (min-width: 1024px) {
    gap: 0.5rem;
    //MANUAL OVERRIDE Start
    // .card {
    // height: 9.75rem;
    // }
    //MANUAL OVERRIDE End
  }
}