.staticImageMapWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.staticImageMap {
  max-width: 100%;
  max-height: 100%;
}
