.iconTextDescriptionDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .icon {
    display: flex; // MANUAL OVERRIDE
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .description {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1; // MANUAL OVERRIDE
    margin-left: 0.5rem;
  }
  
}

.iconTextDescriptionWithLogo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .icon {
    display: flex; // MANUAL OVERRIDE
    width: 1.5rem;
    height: 1.5rem;
    align-self: center; // MANUAL OVERRIDE
  }
  
  .description {
    display: flex;
    width: unset;
    flex: 0 1 auto; // MANUAL OVERRIDE
    margin-left: 0.5rem;
  }
  
  .logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: unset;
    align-self: stretch;
    margin-left: 0.5rem;
  }
  
  .logo {
    display: block;
    max-width: 4rem; // MANUAL OVERRIDE
  }
  
}

// MANUAL OVERRIDE STARTS
.iconTextDescriptionLarge {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  gap: 1rem;

  .icon {
    overflow: hidden;
    align-self: flex-start;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      img {
        height: 2.5rem;
        flex: 0 0 2.5rem;
      }
    }
  }

  .description {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    flex: 0 1 auto;

    p {
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.875rem;

      @media (max-width: 1023px) {
        font-size: 0.875rem;
        line-height: 1.3125rem;
      }
    }
  }
}
// MANUAL OVERRIDE ENDS

