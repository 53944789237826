@import '../../../resources/scss/colors.scss';
@import '../../../resources/scss/z-indexes.scss';

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: $spinner-z-index;
}

.spinner {
  overflow: hidden;
  width: 4rem;
  height: 4rem;
  border-width: 0.25rem;
  border-style: solid;
  border-radius: 50%;
  box-sizing: border-box;
  animation: spin 2s linear infinite;
}

.lightSpinner {
  border-color: $white;
  border-top-color: transparent;
}

.darkSpinner {
  border-color: $interaction-blue-60;
  border-top-color: transparent;
}

.overlayingSpinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.screenReaderOnly {
  overflow: hidden;
  position: absolute;
  top: auto;
  left: -10000px;
  width: 1px;
  height: 1px;
}
