.tabRoundedDarkActive {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .text {
    display: flex;
  }
  
  .indicator {
    display: flex; // MANUAL OVERRIDE
    border-radius: 2px;
    background-color: rgb(2, 118, 177);
    margin-top: 0.5rem;
    align-self: stretch; // MANUAL OVERRIDE
    height: 0.25rem; // MANUAL OVERRIDE
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(2, 118, 177);
    outline-offset: 0;
  
  }
}

.tabRoundedDarkDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .text {
    display: flex;
  }
  
  .indicator {
    display: block;
    border-radius: 2px;
    margin-top: 0.5rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(2, 118, 177);
    outline-offset: 0;
  }
}

.tabSquareLightActive {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .text {
    display: flex;
  }
  
  .indicator {
    display: flex; // MANUAL OVERRIDE
    border-radius: 2px; // MANUAL OVERRIDE
    background-color: rgb(255, 255, 255);
    margin-top: 1.5rem;
    align-self: stretch; // MANUAL OVERRIDE
    height: 0.25rem; // MANUAL OVERRIDE
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(255, 255, 255);
    outline-offset: 0;
  
  }
}

.tabSquareLightDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .text {
    display: flex;
  }
  
  // MANUAL OVERRIDE STARTS
  .indicator {
    display: flex;
    margin-top: 1.5rem;
    align-self: stretch;
    height: 0.25rem;
  }
  // MANUAL OVERRIDE ENDS
  
  &:focus-visible {
    outline: 2px dashed rgb(255, 255, 255);
    outline-offset: 0;
  
    .indicator {
      // MANUAL OVERRIDE STARTS
      display: flex;
      margin-top: 1.5rem;
      align-self: stretch;
      height: 0.25rem;
      border-radius: 2px;
      // MANUAL OVERRIDE ENDS
      background-color: rgb(244, 244, 244);
    }
    
  }
  &:hover {
  
    .indicator {
      // MANUAL OVERRIDE STARTS
      display: flex;
      margin-top: 1.5rem;
      align-self: stretch;
      height: 0.25rem;
      border-radius: 2px;
      // MANUAL OVERRIDE ENDS
      background-color: rgb(244, 244, 244);
    }
    
  }
}
