@import '../../../resources/scss/z-indexes.scss';

.accountMenuBlock {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $modal-z-index;
}

.modalDialog {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 0 0 auto;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.modalHeader {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 0 0 auto;
}

.modalBody {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 1 0 auto;
  overflow: hidden;
  overflow-y: auto;
}
