@import '../../../resources/scss/colors.scss';

$capital-one-blue: #004A79;
$vivid-seats-blue: #04092C;

.logo {
  display: flex;
}

.capitalOneLogo {
  &.lightLogo path[class=text] {
    fill: $white;
  }

  &.darkLogo path[class=text] {
    fill: $capital-one-blue;
  }
}

.capitalOneEntertainmentLogo {
  &.lightLogo path[class=text] {
    fill: $white;
  }

  &.darkLogo path[class=text] {
    fill: $capital-one-blue;
  }
}

.vividSeatsLogo {
  &.lightLogo path[class=text] {
    fill: $white;
  }

  &.darkLogo path[class=text] {
    fill: $vivid-seats-blue;
  }
}

.poweredByVividSeatsLogo {
  &.lightLogo path[class=text] {
    fill: $white;
  }

  &.darkLogo path[class=text] {
    fill: $vivid-seats-blue;
  }
}
