@import '../../../resources/scss/colors.scss';

$border-color: $digital-grey-10;

.tabsContainer {
  display: flex;
  align-self: stretch;

  @media (min-width: 1024px) {
    padding: 0.75rem 0 0 0;

    .leftSpacer {
      min-width: 1.5rem;
      border-bottom: 1px solid $border-color;
    }
  }

  .rightSpacer {
    flex-grow: 1;
    border-bottom: 1px solid $border-color;
  }
}

// Account for MLB banner that is conditionally shown on Exclusives tab
.paginatedItemListWithBanner {
  @media (min-width: 1024px) {
    padding-top: 0;
  }
}
