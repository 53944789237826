.fAQList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;

  .fAQItem {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
