@import '../../../resources/scss/colors.scss';

$border-radius: 0.75rem;

$outline-width: 0.125rem;
$outline: $outline-width dashed $interaction-blue-50;

.mlbCard {
  display: flex;
  width: 100%;
  min-height: 19.875rem;
  border-radius: $border-radius;
  box-shadow: 0 0.125rem 0.5rem 0 #00000033;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  .image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: linear-gradient(0deg, rgba(0, 19, 43, 0.4), rgba(0, 19, 43, 0.4)), url('../../../resources/images/MLBCard.png');
    transition: transform 0.3s ease;
  }

  // Scale event image up/down on hover in/out
  &:hover {
    .image {
      transform: scale(1.2);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 1rem 1.25rem 0.75rem 1.25rem;
    box-sizing: border-box;
    position: absolute;
  }

  .topSubContent {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .bottomSubContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: calc(0.5rem - 1px); // Account for 1px for top border
    border-top: 1px solid $charcoal-10;
  }

  .buttonIcon {
    width: 2rem;
    height: 2rem;
  }

  .overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: $border-radius;
    outline: $outline;
    outline-offset: -$outline-width;
  }

  &:focus-visible {
    outline: none;

    .overlay {
      display: block;
    }
  }
}
