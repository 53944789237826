@import '../../../resources/scss/colors.scss';

.checkoutTotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: calc(1rem - 1px) 1rem 1rem 1rem; // Account for 1px for top border
  border-top: 1px solid $digital-grey-10;
  background: $white;
  box-sizing: border-box;
}

.leftContent {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.eventName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nextStepButton {
  &:focus-visible {
    outline: 0.125rem dashed #FF9900;
    outline-offset: 0;
  }

  p {
    text-wrap: nowrap;
  }
}
