@import '../../../resources/scss/colors.scss';
@import '../../../resources/scss/z-indexes.scss';

.signInDisclaimer {
  display: flex;
  gap: 0.5rem;
  width: 100%;
  padding: 0.75rem;
  background-color: $interaction-blue-10;
  position: fixed;
  top: calc(var(--top-disclaimer-height) + var(--top-nav-height)); // Account for the height of the top fixed content (TopDisclaimer and TopNav)
  z-index: $sign-in-disclaimer-z-index;
  box-sizing: border-box;

  @media (min-width: 1024px) {
    justify-content: center;
  }

  .icon {
    margin-top: 0.125rem;
  }

  .underlineText {
    text-decoration: underline;
  }
}
