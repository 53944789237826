@import '../../../resources/scss/colors.scss';

.marketingOptinMlbContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;

  .toggleButton {
    justify-content: flex-start;
    width: fit-content;
  }

  .toggleButtonIcon {
    flex-shrink: 0;
    width: fit-content;
    padding-right: 0.3125rem;
    box-sizing: border-box;

    path {
      fill: #444649;
    }
  }

  .toggleButtonText p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: $text-subdued-light;
  }

  .expandedContent {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .expandedText {
      line-height: 1.5rem;
    }

    .underlineText {
      color: $text-subdued-dark;
      text-decoration: underline !important; // Override CSS from reset.css
      text-underline-offset: 0.1875rem;
    }

    .boldText {
      font-weight: 600;
    }

    .subscribeButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      height: 3rem;
      padding: 0.75rem 1.5rem;
      background: $white;
      outline: 1px solid #D3D3DC;
      border-radius: 0.25rem;
      box-sizing: border-box;

      .subscribeButtonText p {
        font-size: 0.875rem;
        color: $text-base-dark;
      }

      &.isSubscribed {
        background: $interaction-blue-10;
        outline: none;

        .subscribeButtonText p {
          color: $core-blue-70;
        }
      }

      &:focus-visible,
      &.isSubscribed:focus-visible {
        outline: 1px dashed $interaction-blue-50;
        outline-offset: -1px;
      }
    }
  }
}
