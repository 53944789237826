.eventDateStatusDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .dateAndTime {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
  }
  
  .date {
    display: flex;
  }
  
  .time {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
}

.eventDateStatusWithTag {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .tag {
    display: flex;
  }
  
  .dateAndTime {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .date {
    display: flex;
  }
  
  .time {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
}
