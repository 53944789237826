.tagInformationRegular {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.1875rem 0.5rem 0.1875rem 0.5rem;
  border-radius: 100px;
  background-color: rgb(225, 245, 255);

  .label {
    display: flex;
  }
  
}

.tagInformationSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem;
  border-radius: 100px;
  background-color: rgb(225, 245, 255);

  .label {
    display: flex;
  }
  
}

.tagNegativeRegular {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.1875rem 0.5rem 0.1875rem 0.5rem;
  border-radius: 100px;
  background-color: rgb(255, 229, 230);

  .label {
    display: flex;
  }
  
}

.tagNegativeSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem;
  border-radius: 100px;
  background-color: rgb(255, 229, 230);

  .label {
    display: flex;
  }
  
}

.tagNeutralSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem;
  border-radius: 100px;
  background-color: rgb(244, 244, 244);

  .label {
    display: flex;
  }
  
}

.tagPositiveRegular {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.1875rem 0.5rem 0.1875rem 0.5rem;
  border-radius: 100px;
  background-color: rgb(218, 232, 216);

  .label {
    display: flex;
  }
  
}

.tagPositiveSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem;
  border-radius: 100px;
  background-color: rgb(218, 232, 216);

  .label {
    display: flex;
  }
  
}

.tagWarningRegular {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.1875rem 0.5rem 0.1875rem 0.5rem;
  border-radius: 100px;
  background-color: rgb(255, 241, 208);

  .label {
    display: flex;
  }
  
}

.tagWarningSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem;
  border-radius: 100px;
  background-color: rgb(255, 241, 208);

  .label {
    display: flex;
  }
  
}
