@import '../../../resources/scss/colors.scss';

$quantity-and-rewards-balance-gap: 0.75rem;

.preCheckoutDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &.lightPreCheckoutDetails {
    background-color: $white;

    .infoContainer {
      border-bottom-color: $digital-grey-10;
    }

    .bottomContent {
      background-color: $white;
      border-top-color: $digital-grey-10;
    }

    .checkoutButton {
      background-color: $interaction-blue-50;

      .checkoutButtonText p {
        color: $white;
      }
    }
  }

  &.darkPreCheckoutDetails {
    background-color: $core-blue-70;

    .infoContainer {
      border-bottom-color: #BCBFC780;
    }

    .bottomContent {
      background-color: $core-blue-70;
      border-top-color: #BCBFC780;
    }

    .checkoutButton {
      background-color: $white;

      .checkoutButtonText p {
        color: $interaction-blue-50;
      }
    }
  }
}

.topContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
  box-sizing: border-box;
  position: relative;
}

.header {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;

  .headerTopContent {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    .sectionAndRow {
      h1 {
        font-size: 1.25rem;
        line-height: 1.875rem;
      }
    }
  }
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 1rem;
  padding-bottom: calc(1rem - 1px); // Account for 1px for bottom border
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.ticketsBy {
  display: flex;
  gap: 0.5rem;
}

.ticketsByText {
  &.ticketsByCapitalOneText {
    padding-top: 0.125rem; // Match the height of 24px of Capital One logo
  }
}

.ticketsByLogo {
  flex-shrink: 0;

  &.ticketsByCapitalOneLogo {
    max-width: 4.1875rem;
    height: 1.5rem;
  }

  &.ticketsByVividSeatsLogo {
    max-width: 5.375rem;
    height: 1.3125rem; // Match the line height of 21px of 'tickets by' copy
  }
}

.quantityAndRewardsBalance {
  display: flex;
  flex-direction: column;
  gap: $quantity-and-rewards-balance-gap;
  padding-top: 1rem;
}

.signInDialog {
  margin-bottom: calc(1rem - #{$quantity-and-rewards-balance-gap});
}

.bottomContent {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  padding: calc(1rem - 1px) 1rem 1rem 1rem; // Account for 1px for top border
  border-top-width: 1px;
  border-top-style: solid;
  box-sizing: border-box;
}

.checkoutButton {
  border-radius: 0.25rem;
  padding: 0.5625rem 1.5rem;
}

.ticketsGuaranteedDescription {
  justify-content: center;

  .ticketsGuaranteedDescriptionIcon {
    display: flex;
    width: 1.125rem;
    min-width: unset;
    height: 1.125rem;
  }

  .ticketsGuaranteedDescriptionText p {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
}

.closeButton {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: transparent;

  &:active,
  &:hover {
    border-radius: 0.25rem;
    background-color: #ffffff33;
  }

  &:focus-visible {
    border-radius: 0.25rem;
    outline: 1px dashed $white;
    outline-offset: -1px;
  }
}

.exclusiveBadge {
  max-width: calc(100% - 1.5rem); //To accommodate the close button of modal and stop overlapping of badge to close button for dark theme.
}
