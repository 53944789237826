.infoDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .eventLogistics {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .eventDateStatus {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .venue {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
