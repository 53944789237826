@import '../../../resources/scss/colors.scss';

.listings {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.listing {
  padding: 1rem;
  border-radius: 0;
  outline: 1px solid $digital-grey-10;

  &:focus-visible {
    z-index: 1;
  }
}
