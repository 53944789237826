@import '../../../resources/scss/colors.scss';
@import '../../../resources/scss/z-indexes.scss';

.onBoardingModalContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: $core-blue-70;
  box-shadow: 0 0.125rem 1.5625rem 0 #00000033;
  overflow-y: auto;

  @media (min-width: 1024px) {
    flex-direction: row-reverse;
  }

  .topNav {
    position: unset;
    z-index: unset;
    // To adjust padding on mobile so top nav doesn't shrink.
    flex-shrink: 0;
  }

  .imageContainer {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: flex-end;
    flex-shrink: 0;
    height: 22.5rem;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    @media (min-width: 1024px) {
      width: 50%;
      height: 100%;
    }

    // Custom image positioning for step 1
    &.imageContainer-step1 {
      @media (min-width: 1024px) {
        background-position: 67% 50%;
      }

      @media (min-width: 1440px) {
        background-position: 66% 50%;
      }

      @media (min-width: 1920px) {
        background-position: 64% 50%;
      }
    }

    // Custom image positioning for step 2
    &.imageContainer-step2 {
      background-position: 14% 0;

      @media (min-width: 768px) {
        background-position: 0% 63%;
      }

      @media (min-width: 1024px) {
        background-position: 5% 50%;
      }

      @media (min-width: 1440px) {
        background-position: 5% 50%;
      }

      @media (min-width: 1920px) {
        background-position: 0% 50%;
      }
    }

    // Custom image positioning for step 3
    &.imageContainer-step3 {
      @media (min-width: 1024px) {
        background-position: 53% 50%;
      }

      @media (min-width: 1440px) {
        background-position: 52% 50%;
      }

      @media (min-width: 1920px) {
        background-position: 46% 50%;
      }
    }
  }

  .leftContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    @media (min-width: 1024px) {
      width: 50%;
    }
  }

  .leftContent {
    padding: 0 1rem;

    @media (min-width: 1024px) {
      padding-left: 4.5rem; // to have default 1rem of + 56 left padding for desktop breakpoints. padding for large desktops
    }

    @media (min-width: 1920px) {
      width: 41.625rem;
      align-self: center;
      padding-left: 1rem; // to have default 1rem of padding for large desktops
    }
  }

  .headerContent {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;

    @media (min-width: 1024px) {
      padding: 0;
    }
  }

  .titleContent {
    overflow: hidden;
    padding: 1rem 0 calc(1.25rem - 1px);

    &.titleContent-step2 p {
      @media (max-width: 767px) {
        font-size: 2rem;
        line-height: 2.625rem;
      }
    }

    p {
      font-size: 2.5rem;
      font-weight: 300;
      line-height: 3.25rem;
      max-height: 13rem; // Maximum height the paragraph can reach before truncation
      display: -webkit-box; // Uses a flexible WebKit-based box model for multiline truncation
      line-clamp: 4;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-wrap: break-word; // Forces long words or unbroken strings to wrap onto the next line

      @media (min-width: 1024px) {
        line-clamp: 3;
        -webkit-line-clamp: 3;
      }

      @media (min-width: 1440px) {
        font-size: 3rem;
        line-height: 3.875rem;
      }
    }
  }

  .dividerContent {
    height: 1px;
  }

  .subTitleContent {
    padding: 1rem 0 1.25rem;
  }

  .descriptionContent {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    padding: 1rem 0;
  }

  .textIconDescription {
    display: flex;
    align-items: center;
    gap: 1rem;

    .descriptionIcon {
      width: 2.5rem;
      height: 2.5rem;
      flex-shrink: 0;
    }

    .descriptionText {
      p {
        color: $grey-10 ;

        @media (min-width: 1440px) {
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }

    }
  }

  .accountDetailsContent {
    display: flex;
    flex-direction: column; // Stack items vertically by default for mobile view
    gap: 1.25rem;
    padding: 1rem 0;

    @media (min-width: 1024px) {
      flex-direction: row; // For larger screens, display in two columns
      column-gap: 1.5rem;
    }

    .column {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 1.25rem;

      @media (min-width: 1024px) {
        width: 50%;
      }
    }
  }

  .accountCard {
    padding: 0.5rem 0;
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.625rem;
    width: 100%;
    padding: calc(0.75rem - 1px) 1rem 0.75rem;
    border-top: 1px solid $charcoal-30;
    background: $core-blue-70;
    position: sticky;
    bottom: 0;

    @media (min-width: 1024px) {
      position: static;
    }

    .stepText {
      overflow: hidden;

      p {
        line-height: 1.125rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .nextButton {
      width: 8.6875rem;
      height: 3rem;

      p {
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.875rem;
      }
    }
  }

  .underlineText {
    color: $white;
    text-decoration: underline !important; // Override CSS from reset.css
    text-underline-offset: 0.1875rem;
    font-weight: 600;
  }
}