@import '../../../resources/scss/colors.scss';

$border: 1px solid $digital-grey-10;

.content {
  display: flex;
  gap: 0.5rem;
  padding: 1rem;

  .columnTitle {
    p {
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  }
}

.withBottomBorder {
  padding-bottom: calc(1rem - 1px); // Account for 1px for bottom border
  border-bottom: $border;
}

.paymentInfoTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hyperLink {
  color: $interaction-blue-50;
  cursor: pointer;
}

.termsOfUseCheckbox {
  margin-top: 0.5rem;
}
