.errorBlock {
  display: flex;
  flex-direction: column;
  justify-content: center; // MANUAL OVERRIDE
  align-items: center;
  padding: 3.5rem 1rem 2.5rem 1rem;
  background-color: rgb(244, 244, 244);
  align-self: stretch; // MANUAL OVERRIDE

  .image {
    display: flex;
    width: 6rem;
    height: 6rem;
    align-self: center; // MANUAL OVERRIDE
    flex: 0 0 auto; // MANUAL OVERRIDE
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 2rem;
  }

  .title {
    display: flex;
  }

  .message {
    display: flex;
    width: unset;
    // align-self: stretch; // MANUAL OVERRIDE
    margin-top: 1rem;
  }

  .button {
    display: flex;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
    margin-top: 2rem;
    width: 18rem; // MANUAL OVERRIDE
  }

  @media (min-width: 1024px) {
    padding: 8.5rem 0rem 8.5rem 0rem;

    .button {
      margin-left: auto; // MANUAL OVERRIDE
      margin-right: auto; // MANUAL OVERRIDE
    }

    // MANUAL OVERRIDE STARTS
    .message {
      width: 21.063rem; // MANUAL OVERRIDE
      flex: 0 0 auto; // MANUAL OVERRIDE
    }
    // MANUAL OVERRIDE ENDS

  }
}
