@import '../../../resources/scss/z-indexes.scss';

.myTicketsPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem; // MANUAL OVERRIDE
  background-color: rgb(244, 244, 244);

  .myTicketsBlock {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  @media (min-width: 1024px) {
    padding: 1.5rem 0rem 1.5rem 0rem; // MANUAL OVERRIDE

    .myTicketsBlock {
      width: 60rem;
      height: unset;
      align-self: unset; // MANUAL OVERRIDE
    }
    
  }
}

// MANUAL OVERRIDE START
.modal {
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: $modal-z-index;
}

.modalDialog {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  height: 100%;
  padding: 0rem;
}

.modalContent {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 0 0 auto;
  @media (min-width: 1024px) {
    margin-top: 2.5rem;
    padding-top: 2.5rem;
  }
  > * {
    padding: 0rem 2rem 0rem 2rem !important;
  }
}
.orderDetailsContainer {
  height: 70% !important;
}
.dateCreated {
  margin-left: 0 !important;
}
.hideIcons {
  display: none;
}

// MANUAL OVERRIDE END
