@import '../../../resources/scss/colors.scss';
@import '../../../resources/scss/z-indexes.scss';

$border: 1px solid $digital-grey-10;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 !important; // Override inline styles for padding that are applied by modal
  z-index: $non-blocking-modal-z-index;

  @media (min-width: 1024px) {
    top: var(--top-fixed-height); // Account for the height of the top fixed content (TopDisclaimer, TopNav and SignInDisclaimer)
    width: 22.5rem;
    height: calc(100% - var(--top-fixed-height)); // Account for the height of the top fixed content (TopDisclaimer, TopNav and SignInDisclaimer)
  }
}

.modalDialog {
  width: 100%;
  height: 100%;
}

.modalContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: $white;
  position: relative;
  overflow-y: auto;

  .eventImage {
    display: flex;
    align-self: stretch;
    flex-shrink: 0;
    width: 100%;
    height: 20.625rem;

    @media (min-width: 768px) {
      height: 29rem;
    }

    @media (min-width: 1024px) {
      height: 20.625rem;
    }
  }

  .backToAllTicketsButton {
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    padding: 0.25rem 1rem 0.25rem 0.5rem;
    border-radius: 6.25rem;
    background-color: $white;

    .backToAllTicketsButtonText {
      margin-left: 0;
    }
  }

  .topContent {
    padding: 1rem;

    @media (min-width: 1024px) {
      padding: 1rem calc(1rem - 1px) 1rem 1rem; // Account for 1px for top border
      border-right: $border;
    }
  }

  .bottomContent {
    padding: calc(1rem - 1px) 1rem 1rem 1rem; // Account for 1px for top border

    @media (min-width: 1024px) {
      padding: calc(1rem - 1px) calc(1rem - 1px) 1rem 1rem; // Account for 1px for top and right borders
      border-right: $border;
    }
  }
}
