@import '../../../resources/scss/colors.scss';
@import '../../../resources/scss/z-indexes.scss';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 !important; // Override inline styles for padding that are applied by modal
  background-color: $white;
  z-index: $modal-z-index;

  .modalDialog {
    width: 100%;
    height: 100%;
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.listingsFiltersModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.topContent {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem calc(1rem - 1px) 1rem; // Account for 1px for bottom border
  border-bottom: 1px solid $digital-grey-10;

  .closeButton {
    width: 1.25rem;
    height: 1.25rem;
    background-color: transparent;

    &:hover {
      border-radius: 0.25rem;
      background-color: $custom-blue-10;
    }

    &:focus-visible {
      border-radius: 0.25rem;
      background-color: $custom-blue-10;
      outline: 1px dashed $interaction-blue-50;
      outline-offset: -1px;
    }

    &:active {
      border-radius: 0.25rem;
      background-color: $interaction-blue-10;
    }
  }
}

.bottomContent {
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 1.5rem 1rem;
  gap: 0.5rem
}

.applyFiltersButton {
  border-radius: 0.25rem;
  padding: 0.5625rem 1.5rem;
}

.resetFiltersButton {
  align-self: center;
}
