.thumbnailCard {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  // MANUAL OVERRIDE - removed padding
  border-radius: 8px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 8.75rem;
  height: 8.75rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    
    // MANUAL OVERRIDE STARTS
    -webkit-clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);
    padding: 0.625rem;
    background-color: rgb(16, 37, 63);  
    // MANUAL OVERRIDE ENDS
  }
  
  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
  }
  
}
