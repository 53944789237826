@keyframes slideUp {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}

.modalSlideUp {
  animation: slideUp 0.3s ease-out;
}

.modalSlideDown {
  animation: slideDown 0.3s ease-out;
}
