.teamLogo {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0rem 0rem 0rem;
  border-radius: 100px;
  background-color: rgb(203, 203, 203);
  width: 2.5rem;
  height: 2.5rem;

  .teamInitials {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-transform: uppercase;
    text-align: center;
  }
  
}
