.thumbnailTextItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(244, 244, 244);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    width: unset;
    align-self: stretch;

    // MANUAL OVERRIDE STARTS
    p {
      text-decoration: none;
    }

    // MANUAL OVERRIDE ENDS
  }

  .teamLogo {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
  }

  .button {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 1rem;
    align-items: flex-start; // MANUAL OVERRIDE

    // MANUAL OVERRIDE
    .buttonText {
      display: flex;
      align-items: flex-start;
    }
  }

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1rem;
  }

  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  @media (min-width: 1024px) {

    .icon {
      display: none;
      width: unset;
      height: unset;
    }

    .divider {
      display: none;
      width: unset;
    }

    .button {
      align-items: flex-start; // MANUAL OVERRIDE
      justify-content: flex-start; // MANUAL OVERRIDE
      flex: 0 0 auto; // MANUAL OVERRIDE
    }

    // MANUAL OVERRIDE STARTS
    &:hover {
      .content {
        flex: 0 0 auto;
        align-self: stretch;
      }

      .button {
        border-bottom: 0.125rem solid black;
        margin-bottom: -0.125rem;

        p {
          text-decoration: none;
        }
      }
    }

    &:active {
      .button {
        p {
          text-decoration: none;
        }
      }
    }

    // MANUAL OVERRIDE ENDS

  }
}