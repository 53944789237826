.thumbnailTextList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  .thumbnailTextItem {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1024px) {
    background-color: rgb(244, 244, 244);
    gap: 1rem;

  }
}
