.contactUs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .contactUsItem {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .leftcontent {
    display: none;
  }

  // MANUAL OVERRIDE STARTS
  .mobilecontent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2.5rem;
    align-self: flex-start;
    flex: 1 1 auto;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  // MANUAL OVERRIDE ENDS
  
  .contactUsItem1 {
    display: flex;
    width: unset;
    align-self: stretch;
    // MANUAL OVERRIDE - removed margin-top in favour of gap in mobileContent
  }
  
  .rightContent {
    display: none;
  }
  
  .contactUsItem2 {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 2.5rem;
  }
  
  @media (min-width: 1024px) {
    // MANUAL OVERRIDE STARTS
    .mobilecontent {
      display: none;
    }
    // MANUAL OVERRIDE ENDS

    .content {
      flex-direction: row;
    }
    
    .contactUsItem {
      display: flex; // MANUAL OVERRIDE
      width: unset;
    }
    
    .leftcontent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: unset;
      flex: 1 1 auto;
      gap: 2.5rem; // MANUAL OVERRIDE
      margin-top: 1.5rem; // MANUAL OVERRIDE
    }
    
    .contactUsItem1 {
      display: flex; // MANUAL OVERRIDE
      width: unset;
      margin-top: 0;
    }
    
    .rightContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: unset;
      flex: 1 1 auto;
      margin-left: 2.5rem;
      gap: 2.5rem; // MANUAL OVERRIDE
      margin-top: 1.5rem; // MANUAL OVERRIDE
    }
    
    .contactUsItem2 {
      display: none;
      width: unset;
      margin-top: 0;
    }
    
  }
}
