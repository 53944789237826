.maintenancePage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(244, 244, 244);

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .maintenanceBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.5rem 1rem 2.5rem 1rem;
    > * {
      margin-top: 2rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

}

@media (min-width: 1024px) {
  .maintenancePage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(244, 244, 244);
  
    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      flex: 0 0 auto;
    }
  
    .maintenanceBlock {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2.5rem 1rem 2.5rem 1rem;
      > * {
        margin-top: 2rem;
      }
      align-self: center;
      flex: 0 0 auto;
    }
  
  }
}