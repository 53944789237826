.fAQItemCollapsed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    cursor: pointer; // MANUAL OVERRIDE
  }
  
  .question {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: flex; // MANUAL OVERRIDE
    width: 1.5rem;
    height: 1.5rem;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
}

.fAQItemExpanded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    cursor: pointer; // MANUAL OVERRIDE
  }
  
  .question {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: flex; // MANUAL OVERRIDE
    width: 1.5rem;
    height: 1.5rem;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .answer {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
}
