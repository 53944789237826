@import '../../../resources/scss/colors.scss';

$border: 1px solid $digital-grey-10;

.creditCardInfo {
  gap: 0.5rem;
  padding: 1rem 1rem calc(1rem - 1px) 1rem; // Account for 1px for bottom border
  border-bottom: $border;

  .creditCardIcons {
    gap: 0.5rem;
  }

  .textInput {
    display: flex;
    width: unset;
    height: 2rem;
    align-self: stretch;
    border: 1px solid $text-subdued-light;
    border-radius: 0.125rem;
    box-sizing: border-box;

    &.textInputError {
      border: 0.125rem solid $interaction-red-50 !important;
    }
  }

  .creditCardDetails {
    gap: 1.25rem;
  }

  .creditCardDetail {
    gap: 0.5rem;
  }

  .creditCardNumber {
    max-width: 29.0625rem;
  }

  .creditCardExpiry,
  .creditCardCvv {
    max-width: 10.5625rem;
  }
}
