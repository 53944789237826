.orderDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 8px;

  >* {
    margin-top: 1rem; //MANUAL OVERRIDE
  }

  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(229, 229, 229);
  outline-offset: 0;

  .closeButton {
    display: flex;
    //MANUAL OVERRIDE STARTS
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    align-self: flex-end;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    //MANUAL OVERRIDE ENDS

  }

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    >* {
      margin-top: 1rem; //MANUAL OVERRIDE
    }

    align-self: stretch;
    flex: 0 0 auto; //MANUAL OVERRIDE 

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0; //MANUAL OVERRIDE
      }
    }
  }

  .infoHeader {
    display: flex;
    flex-direction: column; //MANUAL OVERRIDE
    justify-content: flex-start; //MANUAL OVERRIDE
    align-items: flex-start; //MANUAL OVERRIDE
    align-self: stretch;
    flex: 0 0 auto; //MANUAL OVERRIDE

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0; //MANUAL OVERRIDE
      }
    }
  }

  .printButton {
    display: flex;
    flex-direction: row; //MANUAL OVERRIDE
    justify-content: center; //MANUAL OVERRIDE
    align-items: center; //MANUAL OVERRIDE
    align-self: flex-start; //MANUAL OVERRIDE
    flex: 0 0 auto; //MANUAL OVERRIDE

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0; //MANUAL OVERRIDE
      }
    }
  }

  .divider {
    display: flex;
    flex-direction: row; //MANUAL OVERRIDE
    justify-content: flex-start; //MANUAL OVERRIDE
    align-items: flex-start; //MANUAL OVERRIDE
    align-self: stretch;
    flex: 0 0 auto; //MANUAL OVERRIDE

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0; //MANUAL OVERRIDE
      }
    }
  }

  //MANUAL OVERRIDE STARTS
  .dividerVertical {
    margin-right: 1rem;
    display: flex;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .deliveryContent {
    display: none;
  }

  //MANUAL OVERRIDE ENDS
  .deliveryContentMobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    >* {
      margin-top: 0rem; //MANUAL OVERRIDE
    }

    align-self: stretch;
    flex: 0 0 auto; //MANUAL OVERRIDE

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0; //MANUAL OVERRIDE
      }
    }
  }

  .deliveryInfoTitle {
    display: flex;
    flex-direction: row; //MANUAL OVERRIDE
    justify-content: flex-start; //MANUAL OVERRIDE
    align-items: flex-start; //MANUAL OVERRIDE
    align-self: stretch;
    flex: 0 0 auto; //MANUAL OVERRIDE

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0; //MANUAL OVERRIDE
      }
    }
  }

  .additionalTicketInformationList {
    display: flex;
    flex-direction: column; //MANUAL OVERRIDE
    justify-content: flex-start; //MANUAL OVERRIDE
    align-items: flex-start; //MANUAL OVERRIDE

    >* {
      margin-top: 0rem; //MANUAL OVERRIDE
    }

    align-self: stretch;
    flex: 0 0 auto; //MANUAL OVERRIDE

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0; //MANUAL OVERRIDE
      }
    }
  }

  .deliveryDateInfo {
    overflow: hidden; //MANUAL OVERRIDE
    display: flex;
    flex-direction: column; //MANUAL OVERRIDE
    justify-content: flex-start; //MANUAL OVERRIDE
    align-items: flex-start; //MANUAL OVERRIDE 
    align-self: stretch;
    flex: 0 0 auto; //MANUAL OVERRIDE 
  }

  .downloadButton {
    display: flex;
    //MANUAL OVERRIDE STARTS
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 1rem 0.25rem 1rem;
    align-self: flex-start;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    //MANUAL OVERRIDE ENDS
  }

  .ticketContentMobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    >* {
      margin-top: 1rem; //MANUAL OVERRIDE
    }

    align-self: stretch;
    flex: 0 0 auto; //MANUAL OVERRIDE

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0; //MANUAL OVERRIDE
      }
    }
  }

  .ticketContent {
    display: none; //MANUAL OVERRIDE
  }

  .ticketInfoTitle {
    display: flex;
    //MANUAL OVERRIDE STARTS
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    //MANUAL OVERRIDE ENDS
  }

  .highlightMessage {
    display: flex;
    //MANUAL OVERRIDE STARTS
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    //MANUAL OVERRIDE ENDS
  }

  .infoDetails {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }

  //MANUAL OVERRIDE STARTS
  .seatInfo {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .sellerNotes {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .logoContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    >* {
      margin-top: 0.5rem;
    }

    align-self: flex-start;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    width: 18rem;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .logo {
    overflow: hidden;
    align-self: flex-start;
    width: 7.375rem;
    flex: 0 0 1.6875rem;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .billingContentMobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    >* {
      margin-top: 1rem;
    }

    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .billingContent {
    display: none;
  }

  .billingInfoTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .infoOnMultipleLine {
    // this makes so that \n can be used to break lines
    white-space: pre-line;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .phoneNumber {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .paymentMethod {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .totalCardPayment {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    >* {
      margin-top: 0.25rem;
    }

    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .rewardsMilesRedeemed {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    >* {
      margin-top: 0.25rem;
    }

    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .buttonContainer {
    display: none;
  }

  .content {
    display: none;
  }

  .content {
    display: none;
  }

  .leftContent {
    display: none;
  }

  .middleContent {
    display: none;
  }

  .rightContent {
    display: none;
  }
}

//MANUAL OVERRIDE ENDS

@media (min-width: 1024px) {

  //MANUAL OVERRIDE STARTS
  .orderDetails {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 3rem 3rem 3rem;

    >* {
      margin-top: 1rem;
    }

    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(229, 229, 229);
    border-radius: 8px;
    width: 58rem;
    height: 45rem;
    overflow-y: auto;
    overflow-x: hidden;

    .topContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;

      >* {
        margin-left: 0.625rem;
      }

      align-self: stretch;
      flex: 0 0 auto;
      margin-top: 0;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .infoHeader {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-end;
      flex: 1 1 auto;

      &:nth-child(1) {
        margin-left: 0;
        margin-top: 0;
      }
    }

    .printButton {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: flex-end;
      flex: 0 0 auto;

      &:nth-child(1) {
        margin-left: 0;
      }
    }

    .divider {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .ticketContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      >* {
        margin-top: 1rem;
      }

      align-self: flex-start;
      flex: 1 1 auto;

      &:nth-child(1) {
        margin-left: 0;
      }
    }

    .ticketContentMobile {
      display: none;
    }

    .ticketInfoTitle {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      width: 18rem;
      flex: 0 0 auto;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .highlightMessage {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      flex: 0 0 auto;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .infoDetails {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
    }

    .seatInfo {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .sellerNotes {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .logoContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      >* {
        margin-top: 0.5rem;
      }

      align-self: flex-start;
      flex: 0 0 auto;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .label {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      width: 18rem;
      flex: 0 0 auto;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .logo {
      overflow: hidden;
      align-self: flex-start;
      width: 7.375rem;
      flex: 0 0 1.6875rem;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .deliveryContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      >* {
        margin-top: 1rem;
      }

      align-self: flex-start;
      flex: 0 0 20rem;

      &:nth-child(1) {
        margin-left: 0;
      }
    }

    .deliveryContentMobile {
      display: none;
    }

    .deliveryInfoTitle {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .additionalTicketInformationList {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      >* {
        margin-top: 0rem;
      }

      align-self: stretch;
      flex: 0 0 auto;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .deliveryDateInfo {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      width: 18rem;
      flex: 0 0 2.5625rem;
    }

    .downloadButton {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.25rem 1rem 0.25rem 1rem;
      align-self: flex-start;
      flex: 0 0 auto;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .billingContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 3rem;

      >* {
        margin-top: 1rem;
      }

      align-self: stretch;
      flex: 0 0 auto;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .billingContentMobile {
      display: none;
    }

    .billingInfoTitle {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      width: 60rem;
      flex: 0 0 auto;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .infoOnMultipleLine {
      // this makes so that \n can be used to break lines
      white-space: pre-line;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .phoneNumber {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .paymentMethod {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 2.6875rem;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .totalCardPayment {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      >* {
        margin-top: 0.25rem;
      }

      align-self: stretch;
      flex: 0 0 auto;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .closeButton {
      display: none;
    }

    .buttonContainerMobile {
      display: none;
    }

    .buttonContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      .closeButton {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        align-self: flex-start;
        flex: 0 0 auto;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .leftContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      >* {
        margin-top: 1.5rem;
      }

      align-self: flex-start;
      flex: 1 1 auto;
    }

    .middleContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      >* {
        margin-top: 1.5rem;
      }

      align-self: flex-start;
      flex: 1 1 auto;
    }

    .rightContent {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      >* {
        margin-top: 0.25rem;
      }

      align-self: flex-start;
      flex: 1 1 auto;
    }

    .rewardsMilesRedeemed {
      display: none;
    }
  }

  //MANUAL OVERRIDE ENDS
}