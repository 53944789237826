@import '../../../resources/scss/colors.scss';

.iconTextContainer {
  display: flex;
  gap: 0.5rem;

  .icon {
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
  }
}
