.linkPath {
  text-decoration: none;
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }
}
