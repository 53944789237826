@import '../../../resources/scss/colors.scss';
@import '../../../resources/scss/z-indexes.scss';

.mapTooltip {
  color: $white;
  background-color: $black;
  padding: 0.5rem 0.75rem 0.5rem;
  border-radius: 0.25rem;
  pointer-events: none;
  z-index: $tooltip-z-index;
}

.mapTooltipArrow {
  color: $black;
  padding: 0;
  z-index: $tooltip-z-index;
}
