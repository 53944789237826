.dropdownMenuItemIndicatorText {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .divider1 {
    display: block;
    width: 0.25rem;
    height: unset;
    align-self: stretch;
  }
  
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.25rem 0rem 0.25rem 0rem;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .description {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  &:active {
    overflow: hidden;
  
    .info {
      padding: 0.25rem 0.75rem 0.25rem 0rem;
    }
    
  }
  &:focus-visible {
    overflow: hidden;
    outline: 1px dashed rgb(2, 118, 177);
    outline-offset: 0;
  
    .info {
      padding: 0.25rem 0.75rem 0.25rem 0rem;
    }
    
  }
  &:hover {
  
    .info {
      padding: 0.25rem 0.75rem 0.25rem 0rem;
    }
    
  }
}

.dropdownMenuItemText {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.1875rem 0.5rem 0.25rem 0.5rem;
    width: unset;
    align-self: stretch;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  &:focus-visible {
    border: 1px dashed rgb(2, 101, 151);
    outline-offset: 0;
  
  }
  // MANUAL OVERRIDE STARTS
  &:hover {
    .content {
      p {
        color: #026597;
      }
    }
  }
  // MANUAL OVERRIDE ENDS
}

.dropdownMenuItemIconText {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.1875rem 0.5rem 0.25rem 0.5rem;
    width: unset;
    align-self: stretch;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
}
