@import '../../../resources/scss/z-indexes.scss';

.modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 !important; // Override inline styles for padding that are applied by modal
  z-index: $modal-z-index;
}

.modalDialog {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 0 0 auto;
  height: 100%;
  padding: 0;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 0 0 auto;
  height: 100%;
}
