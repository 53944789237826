.contentLoadErrorBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  height: 17.5rem;

  .icon {
    display: block;
    width: 4rem;
    height: 4rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 1rem 0rem;
    width: unset;
    align-self: stretch;
  }

  .message {
    display: flex;
    width: unset;
    align-self: stretch;
    justify-content: center; // MANUAL OVERRIDE
  }

  .title {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }

  @media (min-width: 1024px) {
    height: 100%; // MANUAL OVERRIDE
    width: 100%; // MANUAL OVERRIDE
    justify-content: center; // MANUAL OVERRIDE
    align-items: center; // MANUAL OVERRIDE

  }
}