.landingPageBanner {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  // MANUAL OVERRIDE STARTS
  background-color: rgb(255, 255, 255);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 2 auto;

    :first-child {
      align-self: center;
      margin-left: 1rem;
    }
  }

  .image {
    display: block;
    clip-path: polygon(0 0px, 80% 0, 100% 100%, 0px 100%);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 30%;
  }
}

@media (min-width: 1024px) {
  .landingPageBanner {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;

    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      :first-child {
        align-self: center;
        margin-left: 1rem;
      }
    }

    .image {
      display: block;
      clip-path: polygon(0 0px, 80% 0, 100% 100%, 0px 100%);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      width: 30%;
    }

    // MANUAL OVERRIDE ENDS

  }
}