.valueFree {
  align-self: flex-start;
  flex: 1 1 auto;
  font-family: 'Optimist', 'sans-serif';
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  text-align: left;
  color:#007F9B;
}
