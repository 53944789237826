.marketingOptinMlbContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.divider {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  flex: 0 0 auto;
}

.toggleButtonContainer {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.toggleButtonContent {
  display: flex;
  gap: 0.25rem;

  .toggleButtonIcon {
    height: 1.25rem;
    width: 1.25rem;
    align-self: center;
    margin-left: 0;

    path {
      fill: rgb(105, 105, 105);
    }
  }

  .toggleButtonText {
    h4 {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5rem;
      color: rgb(105, 105, 105);
      text-decoration: none;
    }
  }
}

.expandedContent {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .expandedTitle {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: rgb(53, 54, 57);
  }

  .boldExpandedTitle {
    font-weight: 600;
  }

  .mlbPrivacyUrl {
    color: rgb(53, 54, 57);
    text-decoration: underline !important;
    text-underline-offset: 0.1875rem;
  }
}

.subscribe {
  display: flex;
  align-self: flex-start;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(211, 211, 220);
  height: 3rem;
  padding: 0.75rem 1.5rem 0.75rem 1.5rem;
  gap: 0.25rem;
  border-radius: 0.25rem;

  .subscribeIcon {
    display: none;
  }

  .subscribeText {
    margin: 0;

    p {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5rem;
      color: rgb(4, 9, 44);
    }
  }
}

.subscribed {
  background: rgb(246, 246, 251);

  .subscribedIcon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;

    path {
      fill: rgb(71, 75, 94);
    }
  }

  .subscribedText {
    p {
      color: rgb(71, 75, 94);
    }
  }
}
