@import '../../../resources/scss/colors.scss';

.paginatedListContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.itemList {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    padding: 0 0.75rem;
  }

  @media (min-width: 1024px) {
    padding: 1rem 1.5rem 0 1.5rem;
  }
}

.seeMoreContainer {
  display: flex;
  justify-content: center;
  padding: calc(1rem - 1px) 1rem 1rem 1rem; // Account for 1px for top border
  border-top: 1px solid $digital-grey-10;
}
