.helpPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(244, 244, 244);

  .questionsBlock {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1024px) {

    .questionsBlock {
      width: 60rem;
      align-self: center; // MANUAL OVERRIDE
    }
    
  }
}
