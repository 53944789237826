@import '../../../resources/scss/colors.scss';

.rewardsSwitcherContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem 0.5rem 1rem;

  .rewardsSwitcher {
    width: 9.25rem;
    flex-shrink: 0;
  }
}
