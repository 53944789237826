@import '../../../resources/scss/colors.scss';
@import '../../../resources/scss/z-indexes.scss';

.backdrop {
  position: fixed;
  top: calc(var(--top-disclaimer-height) + var(--top-nav-height)); // Account for the height of the top fixed content (TopDisclaimer and TopNav)
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(1.25rem);
  z-index: $backdrop-z-index;
}

// Add a white layer to the backdrop
.backdrop::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  opacity: 0.5;
  z-index: -1;
}
