@import '../../../resources/scss/colors.scss';

$border: 1px solid $digital-grey-10;

// Increase CSS specificity
.nonGuestListEventPage.nonGuestListEventPage.nonGuestListEventPage {
  --max-height: calc(100vh - var(--top-fixed-height)); // Account for the height of the top fixed content (TopDisclaimer, TopNav and SignInDisclaimer)

  display: flex;
  flex-direction: row; // Override main layout styles
  align-items: flex-start; // Override main layout styles
  height: var(--max-height);
  background-color: $white;
}

.leftContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: var(--max-height);
  overflow-y: hidden;

  &.visibilityHidden {
    visibility: hidden;
  }

  @media (min-width: 1024px) {
    width: 22.5rem;
    border-right: $border;
  }

  .leftSubContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    overflow-y: auto;

    .leftTopSubContent {
      display: flex;
      flex-direction: column;
    }

    .leftCenterSubContent {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      // On mobiles min height 620px is the sum of heights of event info header, map, sign-in dialog box, "Choose your seats" banner, rewards switcher, and 1 listing card.
      @media (max-width: 1023px) and (min-height: 620px) {
        overflow-y: auto;
      }

      // On desktops min height 485px is the sum of heights of event info header, sign-in dialog box, "Choose your seats" banner, rewards switcher, 1 listing card, and bottom filters (ticket quantity, min/max price slider).
      @media (min-width: 1024px) and (min-height: 485px) {
        overflow-y: auto;
      }
    }

    .leftBottomSubContent {
      display: flex;
      flex-direction: column;
      outline: $border;
    }
  }
}

.rightContent {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex-grow: 1;
}

.mapContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16.25rem;
  border-bottom: $border;
  box-sizing: border-box;

  @media (min-width: 1024px) {
    height: 100%;
    border-bottom: none;
  }
}

.signInDialog {
  margin: 1rem;
}

// Note: Listing header is displayed only if there is at least one listing card rendered.
.listingsHeader {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem calc(1rem - 1px) 1rem; // Account for 1px for bottom border
  border-bottom: $border;

  .listingsFiltersButton {
    width: 1.5rem;
    height: 1.5rem;
    background-color: transparent;

    &:hover {
      border-radius: 0.25rem;
      background-color: $custom-blue-10;
    }

    &:focus-visible {
      border-radius: 0.25rem;
      background-color: $custom-blue-10;
      outline: 1px dashed $interaction-blue-50;
      outline-offset: -1px;
    }

    &:active {
      border-radius: 0.25rem;
      background-color: $interaction-blue-10;
    }
  }
}

// Note: Listing header is displayed only if there is at least one listing card rendered.
// [data-testid="listing-card"] is added to each instance of ListingCard component.
.nonGuestListEventPage:has([data-testid="listing-card"]) .listingsHeader {
  display: flex;
}

.rewardsSwitcher {
  padding-bottom: calc(0.5rem - 1px); // Account for 1px for bottom border
  border-bottom: $border;
}

.nonGuestListEventPage:has(.signInDialog) {
  .mapContainer {
    border-bottom: none;
  }

  .listingsHeader {
    padding-top: calc(1rem - 1px); // Account for 1px for top border
    border-top: $border;
  }
}
