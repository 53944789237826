.accountCards {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.accountCard {
  padding: 0.25rem 2rem 1rem 2rem;

  @media (min-width: 1024px) {
    padding: 0 1.5rem 1.25rem 2.5rem;
  }
}
