@import '../../../resources/scss/colors.scss';

.rewardsBalance {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 0.5rem;
  padding: 1rem 0 0.5rem 0;
}

.lightRewardsBalance {
  background-color: $grey-10;
}

.darkRewardsBalance {
  background: #FFFFFF1A;
}

.title {
  padding: 0 1rem;
}

.accountCardRadioButtons {
  display: flex;
  flex-direction: column;
}

.accountCardRadioButton {
  padding: 0.75rem 1rem;
}
