.teamHomeAwayImageLong {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 5.5rem 1.5rem 5.5rem;
  background-color: rgb(203, 203, 203);
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 18rem;
  height: 10rem;

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .text {
    display: block;
    color: rgb(203, 203, 203);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-transform: uppercase;
    text-align: center;
  }
  
  .teamInitials {
    display: block;
    color: rgb(203, 203, 203);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-transform: uppercase;
    text-align: center;
  }
  
}

.teamHomeAwayImageSquare {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  background-color: rgb(203, 203, 203);
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 9.25rem;
  height: 9.25rem;

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .text {
    display: block;
    color: rgb(203, 203, 203);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-transform: uppercase;
    text-align: center;
  }
  
  .teamInitials {
    display: block;
    color: rgb(203, 203, 203);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-transform: uppercase;
    text-align: center;
  }
  
}
