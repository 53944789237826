.sportTeamsListBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    // padding: 1rem 1rem 1rem 1rem; // MANUAL OVERRIDE
    margin-bottom: 1rem; // MANUAL OVERRIDE
    margin-left: 0.5rem; // MANUAL OVERRIDE
    padding-left: 1rem; // MANUAL OVERRIDE
    background-color: rgb(244, 244, 244);
    width: unset;
    align-self: stretch;
  }

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }

  .thumbnailTextList {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;

  }

  @media (min-width: 1024px) {
    background-color: rgb(244, 244, 244);

    .divider {
      display: none;
      width: unset;
    }

    // MANUAL OVERRIDE STARTS
    .thumbnailTextList {
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 65rem;
    }

    // MANUAL OVERRIDE ENDS

  }
}