.sportTeamsNearLocationBlockEmpty {
  display: none; // MANUAL OVERRIDE
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  background-color: rgb(244, 244, 244);

  .filter {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .emptyStateBanner {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .divider {
    display: none;
  }
  
  @media (min-width: 1024px) {

    .emptyStateBanner {
      margin-top: 2rem;
    }
    
    .divider {
      display: flex;
      width: unset;
      align-self: stretch;
      margin-top: 2rem;
    }
    
  }
}

.sportTeamsNearLocationBlockFiled {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  background-color: rgb(244, 244, 244);

  .filter {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .thumbnailCardList {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
}

.sportTeamsNearLocationBlockFilled {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(244, 244, 244);

  .filter {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .thumbnailCardList {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 2rem;
  }
  
  .divider {
    display: none; // MANUAL OVERRIDE
  }
  
  // MANUAL OVERRIDE STARTS
  @media (min-width: 1024px) {
    .divider {
      display: flex;
      width: unset;
      align-self: stretch;
      margin-top: 2rem;
    }    
  }
  // MANUAL OVERRIDE ENDS
}
