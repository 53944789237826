.limitedAccessBlock {
  height: 100%;
}

.limitedAccessBlockContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  max-width: 37.8125rem;
  height: 100%;
  padding: 0.75rem 1rem 2rem 1rem;
  margin: auto;
  box-sizing: border-box;

  .actionButton {
    width: 100%;
    height: 3rem;
    margin-top: 1rem;
  }
}
