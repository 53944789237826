@import '../../../resources/scss/colors.scss';

$border-radius-mobile: 0.375rem;
$border-radius-tablet: 0.75rem;

$outline-width: 0.125rem;
$outline: $outline-width dashed $interaction-blue-50;

.performerCard {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: $border-radius-mobile;
  box-shadow: 0 0.125rem 0.5rem 0 #00000033;
  width: 100%;
  position: relative;

  @media (min-width: 768px) {
    border-radius: $border-radius-tablet;
  }

  .topContent {
    display: flex;
    min-height: 6.625rem;
    border-radius: $border-radius-mobile $border-radius-mobile 0 0;
    overflow: hidden;

    @media (min-width: 768px) {
      min-height: 10.5rem;
      border-radius: $border-radius-tablet $border-radius-tablet 0 0;
    }

    @media (min-width: 1024px) {
      min-height: 13rem;
    }
  }

  .performerImage {
    transition: transform 0.3s ease;
  }

  // Scale performer image up/down on hover in/out
  &:hover {
    .performerImage {
      transform: scale(1.2);
    }
  }

  .bottomContent {
    display: flex;
    padding: 0.375rem 0.5rem;
    height: 100%;
    border-radius: 0 0 $border-radius-mobile $border-radius-mobile;
    box-sizing: border-box;

    @media (min-width: 768px) {
      padding: 0.75rem;
      border-radius: 0 0 $border-radius-tablet $border-radius-tablet;
    }

    @media (min-width: 1024px) {
      padding: 1.25rem;
    }

    .performerName {
      p {
        display: -webkit-box !important;
        min-height: 2.625rem;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        line-clamp: 2;

        @media (min-width: 768px) {
          min-height: unset;
          font-size: 1rem;
          line-height: 1.5rem;
          -webkit-line-clamp: 1;
          line-clamp: 1;
        }
      }
    }
  }

  .overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: $border-radius-mobile;
    outline: $outline;
    outline-offset: -$outline-width;

    @media (min-width: 768px) {
      border-radius: $border-radius-tablet;
    }
  }

  &:focus-visible {
    outline: none;

    .overlay {
      display: block;
    }
  }
}
