@import '../../../resources/scss/colors.scss';

$border-color: $digital-grey-10;

// Common styles for the container with image and title, e.g. on performer and venue pages
.imageAndTitleContainer {
  display: flex;
  align-self: stretch;
  padding: 1rem 1rem 0 1rem;

  @media (min-width: 768px) {
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  }
}

// Common styles for filters container
.filtersContainer {
  display: flex;
  align-self: stretch;
  flex-wrap: wrap;
  gap: 1rem;
  row-gap: 0.75rem;
  padding: 1rem 1rem calc(1rem - 1px) 1rem; // Account for 1px for bottom border
  border-bottom: 1px solid $border-color;

  @media (min-width: 768px) {
    padding: 1rem 1.5rem calc(1rem - 1px) 1.5rem; // Account for 1px for bottom border
  }
}

// Common styles for an individual filter
// On mobiles filter takes 50% of the screen width
// On desktops filter is left aligned
.filter {
  display: flex;
  width: calc((100% - 1rem) / 2);

  @media (min-width: 768px) {
    width: unset;
  }
}

// Common styles for an individual filter
// Filter takes full screen width
.filterFullWidth {
  display: flex;
  width: 100%;
  flex: 1 1 100%;
}
