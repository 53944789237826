.accountMenuBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem 0.5rem 1rem 1rem;
    background-color: rgb(0, 19, 43);
    width: 100%;
    height: 3.5rem;
    align-self: stretch;
    box-sizing: border-box;
  }

  .stickyTopContent {
    position: absolute;
    top: 0;
  }

  .accountMenu {
    display: flex;
    width: 100%;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }

  @media (min-width: 1024px) {
    .accountMenu {
        width: unset;
    }
  }

  .navMenuList {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .profileMenuList {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    flex-direction: column; // MANUAL OVERRIDE
  }

  .closeButtonIcon {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 0;
  }

}
