.carouselSection {
  display: flex;
  flex-direction: column;
}

.carouselHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.625rem;
  padding-top: 1.5rem;
}

.carouselTitleContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.carouselLinkIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem 0.3125rem 0.5rem;
}
