.dropdownSelectList {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  width: 11.5rem;

  .dropdownMenuItem {
    display: flex;
    width: unset;
    align-self: stretch;

    // MANUAL OVERRIDE STARTS
    .divider {
      display: flex;
      height: 0.0625rem;
    }
    // MANUAL OVERRIDE ENDS
  }
  
}
