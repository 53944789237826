.questionsBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 1rem 1.5rem 1rem;
  background-color: rgb(244, 244, 244);

  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .button {
    display: flex;
    margin-top: 1.5rem;
  }
  
  .fAQList {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  @media (min-width: 1024px) {
    padding: 1.5rem 0rem 1.5rem 0rem;

    .button {
      display: none;
      margin-top: 0;
    }
    
  }
}
