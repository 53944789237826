.tabList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 2rem;

  .tab {
    display: flex;
    max-width: calc(50% - 1rem); // MANUAL OVERRIDE
  }
  
}
