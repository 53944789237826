@import '../../../resources/scss/colors.scss';

.rewardsOnlyPaymentInfo {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 1.125rem;
  padding: 1.8125rem 2.75rem;

  .icon {
    width: 2.375rem;
    height: 2.6875rem;

    path {
      fill: $text-subdued-light;
    }
  }

  .title {
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: #444649;
  }

  .description {
    line-height: 1.125rem;
    color: #444649;
  }
}
