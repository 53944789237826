.eventInfoDefaultDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;

  .title {
    overflow: hidden; // MANUAL OVERRIDE
    display: flex;
    width: unset;
    height: 3rem;
    align-self: stretch;
    max-height: 3rem; // MANUAL OVERRIDE
  }
  
  .eventLogistics {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .dateAndTime {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .date {
    display: flex;
  }
  
  .time {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .locationAndPrice {
    overflow: hidden; // MANUAL OVERRIDE
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .location {
    display: flex;

    // MANUAL OVERRIDE STARTS
    align-self: center;
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 15rem;
    }
    // MANUAL OVERRIDE ENDS
  }
  
  .price {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  @media (min-width: 1024px) {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;

  }
}

.eventInfoDefaultExclusive {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;

  .exclusive {
    display: flex;
    flex-direction: column; // MANUAL OVERRIDE
    justify-content: center; // MANUAL OVERRIDE
    align-items: center; // MANUAL OVERRIDE
  }
  
  .title {
    display: flex;
    width: unset;
    height: 3rem;
    align-self: stretch;
    // MANUAL OVERRIDE - removed margin-top
  }
  
  .eventLogistics {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .dateAndTime {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .date {
    display: flex;
  }
  
  .time {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .locationAndPrice {
    overflow: hidden; // MANUAL OVERRIDE
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .location {
    display: flex;
    align-self: center; // MANUAL OVERRIDE
  }
  
  .price {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }

  // MANUAL OVERRIDE STARTS
  @media (min-width: 1024px) {
    .title {
      margin-top: 0.5rem;
    }
  }
  // MANUAL OVERRIDE ENDS
  
}

.eventInfoSoldOutDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;

  .title {
    display: flex;
    width: unset;
    height: 3rem;
    align-self: stretch;
    width: 15.5rem; // MANUAL OVERRIDE
    flex: 0 0 auto; // MANUAL OVERRIDE
    max-height: 3rem; // MANUAL OVERRIDE
    overflow: hidden; // MANUAL OVERRIDE
  }
  
  .eventLogistics {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .dateAndTime {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .date {
    display: flex;
  }
  
  .time {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .locationAndStatus {
    display: flex;
    flex-direction: column; // MANUAL OVERRIDE
    justify-content: flex-start;
    align-items: flex-start; // MANUAL OVERRIDE
    width: unset; // MANUAL OVERRIDE
    flex-wrap: wrap; // MANUAL OVERRIDE
  }
  
  .location {
    display: flex;
    align-self: center; // MANUAL OVERRIDE
    // MANUAL OVERRIDE STARTS
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 15rem;
    }
    // MANUAL OVERRIDE ENDS
  }
  
  .tag {
    display: flex;
    flex-direction: column; // MANUAL OVERRIDE
    justify-content: center; // MANUAL OVERRIDE
    align-items: center; // MANUAL OVERRIDE
    // MANUAL OVERRIDE - removed margin-left for mobile breakpoint
  }
  
  @media (min-width: 1024px) {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;

    // MANUAL OVERRIDE STARTS
    .title {
      width: unset;
    }

    .locationAndStatus {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .location {
      margin-left: 0.5rem;
    }

    .tag {
      margin-left: 0.5rem;
    }
    // MANUAL OVERRIDE ENDS

  }
}

.eventInfoSoldOutExclusive {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;

  .exclusive {
    display: flex;
    flex-direction: column; // MANUAL OVERRIDE
    justify-content: center; // MANUAL OVERRIDE
    align-items: center; // MANUAL OVERRIDE
  }
  
  .title {
    display: flex;
    width: unset;
    height: 3rem;
    align-self: stretch;
    margin-top: 0.5rem;
    overflow: hidden; // MANUAL OVERRIDE
    max-height: 3rem; // MANUAL OVERRIDE
  }
  
  .eventLogistics {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .dateAndTime {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .date {
    display: flex;
  }
  
  .time {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .locationAndStatus {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    flex-wrap: wrap; // MANUAL OVERRIDE
  }
  
  .location {
    display: flex;
    align-self: center; // MANUAL OVERRIDE
    // MANUAL OVERRIDE STARTS
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 15rem;
    }
    // MANUAL OVERRIDE ENDS
  }
  
  .tag {
    display: flex;
    margin-left: 0.5rem;
    flex-direction: column; // MANUAL OVERRIDE
    justify-content: center; // MANUAL OVERRIDE
    align-items: center; // MANUAL OVERRIDE
  }
  
}
