@import '../../../resources/scss/colors.scss';

$carousel-images-gap: 1px;
$font-size-desktop: 1rem;
$line-height-desktop: 1.5rem;

.guestListEventPage {
  background-color: $core-blue-70;
}

.topContent {
  width: 100%;
}

.carouselImagesContainer {
  gap: $carousel-images-gap;
  padding: 0;
  background-color: $white;
}

.carouselImage {
  height: 11.25rem;

  @media (min-width: 768px) {
    height: 20rem;
  }

  @media (min-width: 1024px) {
    height: 23.75rem;
  }

  @media (min-width: 1440px) {
    height: 26.875rem;
  }

  @media (min-width: 1920px) {
    height: 32.5rem;
  }
}

.singleCarouselImage {
  min-width: 100%;
  max-width: 100%;
}

.doubleCarouselImages,
.multipleCarouselImages {
  min-width: calc((100% - #{$carousel-images-gap}) / 2); // Account for 1px for gap
  max-width: calc((100% - #{$carousel-images-gap}) / 2); // Account for 1px for gap
}

.multipleCarouselImages {
  @media (min-width: 1024px) {
    min-width: calc((100% - 2 * #{$carousel-images-gap}) / 3); // Account for 2 x 1px for gaps
    max-width: calc((100% - 2 * #{$carousel-images-gap}) / 3); // Account for 2 x 1px for gaps
  }
}

.carouselBackwardButton {
  left: 2rem;
}

.carouselForwardButton {
  right: 2rem;
}

.bottomContent {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 1024px) {
    flex-direction: row-reverse; // Display listings on the left and event details on the right
    gap: 1.5rem;
    padding: 2rem;
  }

  @media (min-width: 1440px) {
    gap: 2rem;
    max-width: 90rem;
    padding: 2rem 3rem;
  }
}

.eventDetails {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem 1rem 0 1rem;
  border-bottom: 1px solid $charcoal-40;
  overflow-x: hidden;

  @media (min-width: 1024px) {
    gap: 2rem;
    padding: 0;
    border-bottom: none;
  }

  .eventDetailsHeader {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-bottom: 1rem;

    @media (min-width: 1024px) {
      padding-bottom: 0;
    }
  }

  .eventNameView h1 {
    @media (max-width: 1023px) {
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  }

  .eventDescription {
    padding-bottom: 0.75rem;

    @media (min-width: 1024px) {
      padding-bottom: 0;

      p {
        font-size: $font-size-desktop;
        line-height: $line-height-desktop;
      }
    }
  }

  @media (min-width: 1024px) {
    .eventInfoDetailIcon {
      margin-top: 0.125rem;
    }

    .eventInfoDetailText p {
      font-size: $font-size-desktop;
      line-height: $line-height-desktop;
    }
  }
}

.listings {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 1rem;

  @media (min-width: 1024px) {
    width: 19rem;
    padding: 0;
  }

  @media (min-width: 1440px) {
    width: 26.6875rem;
  }

  .signInDialog {
    margin-bottom: 2rem;

    @media (min-width: 1024px) {
      margin-bottom: 1rem;
    }
  }

  .listingsHeader {
    padding-bottom: 1rem;
  }

  .listingsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0rem;

    @media (min-width: 1024px) {
      padding: 0rem;
    }
  }

  .rewardsSwitcher {
    padding: 0.5rem 0rem;
  }
}