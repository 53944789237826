.ticketStatusNegative {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .date {
    display: flex;
  }

  .mobileQuantityTag {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 0.5rem;
  }

  .mobileQuantityAmount {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .quantity {
    display: flex;
  }

  .amount {
    display: flex;
    margin-left: 0.25rem;
  }

  .tag {
    display: flex;
    margin-left: 0.5rem;
  }

  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }

  .topContent1 {
    display: none;
  }

  .title {
    display: flex;
    width: unset;
    min-height: 3rem;
    align-self: stretch;
  }

  .title1 {
    display: flex;
    width: unset;
    min-height: 1.5rem;
    align-self: stretch;
  }

  .eventLogistics {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }

  .eventDateStatus {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .location {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .quantityTag {
    display: none;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;

    .content {
      flex: 1 1 auto;
    }

    .topContent {
      flex-direction: column;
      width: unset;
      align-self: stretch;
      align-items: flex-start;
    }

    .date {
      width: unset;
      min-height: 1.125rem;
      align-self: stretch;
    }

    .mobileQuantityTag {
      display: none;
    }

    .bottomContent {
      margin-top: 0;
    }

    .topContent1 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: unset;
      align-self: stretch;

      .title1 {
        display: flex;
        width: unset;
        min-height: 1.5rem;
        align-self: stretch;
      }
    }

    .title {
      display: none;
      width: unset;
      height: unset;
    }

    .location {
      min-height: 1.3125rem;
    }

    .quantityTag {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-left: 1.5rem;
      gap: 3rem;
    }
  }
}

.ticketStatusPositive {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }

  .date {
    display: flex;
  }

  .mobileQuantityTag {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 0.5rem;
  }

  .mobileQuantityAmount {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .quantity {
    display: flex;
  }

  .amount {
    display: flex;
    margin-left: 0.25rem;
  }

  .tag {
    display: flex;
    margin-left: 0.5rem;
  }

  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }

  .topContent1 {
    display: none;
  }

  .title {
    display: flex;
    width: unset;
    min-height: 3rem;
    align-self: stretch;
  }

  .eventLogistics {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }

  .dateAndTime {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }

  .date1 {
    display: flex;
  }

  .time {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }

  .eventDateStatus {
    display: none;
  }

  .location {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .quantityTag {
    display: none;
  }

  .quantityAmount {
    display: none;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;

    .content {
      flex: 1 1 auto;
    }

    .topContent {
      flex-direction: column;
      align-items: flex-start;
    }

    .date {
      width: unset;
      min-height: 1.125rem;
      align-self: stretch;
    }

    .mobileQuantityTag {
      display: none;
    }

    .bottomContent {
      margin-top: 0;
    }

    .topContent1 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: unset;
      align-self: stretch;
    }

    .title {
      display: none;
      width: unset;
      height: unset;
    }

    .dateAndTime {
      display: none;
      width: unset;
    }

    .eventDateStatus {
      display: flex;
      width: unset;
      align-self: stretch;
    }

    .location {
      min-height: 1.3125rem;
    }

    .quantityTag {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-left: 1.5rem;
      gap: 3rem;
    }

    .quantityAmount {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}
