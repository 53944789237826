.performerInfoDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .eventLogistics {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }

  .dateAndTime {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }

  .date {
    display: flex;
  }

  .time {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }

  .location {
    display: none;
  }

  .locationAndPrice {
    display: none; // MANUAL OVERRIDE
  }

  .locationMobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    // MANUAL OVERRIDE STARTS
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
    // MANUAL OVERRIDE ENDS
  }

  .price {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }

  .price {
    display: none;
  }

  @media (min-width: 1024px) {

    .eventLogistics {
      flex-direction: row;
    }

    .dateAndTime {
      display: none;
      width: unset;
    }

    .location {
      display: flex;
    }

    .locationAndPrice {
      display: none;
    }

    .locationMobile {
      display: none; // MANUAL OVERRIDE
    }

    .price {
      display: flex;
      width: unset;
      flex: 1 1 auto;
      margin-left: 0.25rem;
    }

  }
}

.performerInfoSoldOut {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .eventLogistics {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }

  .eventLogistics1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }

  .dateAndTime {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }

  .date {
    display: flex;
  }

  .time {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }

  .locationAndStatus {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }

  .location {
    display: flex;
  }

  .tag {
    display: flex;
    margin-left: 0.5rem;
  }

  .location {
    display: none;
  }

  .soldOut {
    display: none;
  }

  @media (min-width: 1024px) {

    .eventLogistics {
      flex-direction: row;
      align-items: center;
    }

    .eventLogistics1 {
      display: none;
      width: unset;
    }

    .location {
      display: flex;
    }

    .soldOut {
      display: flex;
      margin-left: 0.5rem;
    }

  }
}