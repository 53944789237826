.labelledContent {
  display: flex;
  flex-direction: column;
}

.labelRow {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.labelInfoTooltipIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
}
