.submenuItemList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
  max-height: 20.4375rem;
  padding: 0.25rem 0;
}
