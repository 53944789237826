@import '../../../resources/scss/colors.scss';
@import '../../../resources/scss/z-indexes.scss';

.tooltip {
  position: absolute;
  z-index: $tooltip-z-index;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left; // Fallback for where `start` is not supported
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .865rem;
  word-wrap: break-word;
  opacity: 0;
  cursor: pointer;

  &.show {
    opacity: 0.9;
  }

  .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;

    &::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}

.bs-tooltip-top {
  padding: 0.4rem 0;
  margin: 0.125rem 0;

  .arrow {
    bottom: 0;

    &::before {
      top: 0;
      border-width: 0.4rem calc(0.8rem / 2) 0;
    }
  }

  &.light-tooltip .arrow::before {
    border-top-color: $white;
  }

  &.dark-tooltip .arrow::before {
    border-top-color: $black;
  }
}

.bs-tooltip-right {
  padding: 0 0.4rem;
  margin: 0 0.125rem;

  .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;

    &::before {
      right: 0;
      border-width: calc(0.8rem / 2) 0.4rem calc(0.8rem / 2) 0;
    }
  }

  &.light-tooltip .arrow::before {
    border-right-color: $white;
  }

  &.dark-tooltip .arrow::before {
    border-right-color: $black;
  }
}

.bs-tooltip-bottom {
  padding: 0.4rem 0;
  margin: 0.125rem 0;

  .arrow {
    top: 0;

    &::before {
      bottom: 0;
      border-width: 0 calc(0.8rem / 2) 0.4rem;
    }
  }

  &.light-tooltip .arrow::before {
    border-bottom-color: $white;
  }

  &.dark-tooltip .arrow::before {
    border-bottom-color: $black;
  }
}

.bs-tooltip-left {
  padding: 0 0.4rem;
  margin: 0 0.125rem;

  .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;

    &::before {
      left: 0;
      border-width: calc(0.8rem / 2) 0 calc(0.8rem / 2) 0.4rem;
    }
  }

  &.light-tooltip .arrow::before {
    border-left-color: $white;
  }

  &.dark-tooltip .arrow::before {
    border-left-color: $black;
  }
}

.bs-tooltip-auto {
  &[x-placement^="top"] {
    @extend .bs-tooltip-top;
  }

  &[x-placement^="right"] {
    @extend .bs-tooltip-right;
  }

  &[x-placement^="bottom"] {
    @extend .bs-tooltip-bottom;
  }

  &[x-placement^="left"] {
    @extend .bs-tooltip-left;
  }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: 12.5rem;
  padding: 1rem;
  text-align: left;
  border-radius: 0.25rem;
}

[role=tooltip] {
  .tooltip-inner {
    max-width: 18.5rem;
  }
}

.light-tooltip .tooltip-inner {
  color: $black;
  background-color: $white;
}

.dark-tooltip .tooltip-inner {
  color: $white;
  background-color: $black;
}

.tooltip-accessibility-content {
  display: none;
}
