@import '../../../resources/scss/colors.scss';
@import '../../../resources/scss/z-indexes.scss';

.topDisclaimer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: $digital-grey-100;
  position: fixed;
  top: 0;
  z-index: $top-disclaimer-z-index;
  box-sizing: border-box;
}
