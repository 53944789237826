@import '../../../resources/scss/colors.scss';

.signInDialog {

  // Increase CSS specificity
  .icon.icon {
    width: 1rem;
    height: 1rem;
    margin-top: 0.125rem;
  }

  .underlineText {
    text-decoration: underline;
  }
}
