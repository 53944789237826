@import '../../../resources/scss/colors.scss';

.bannerContainer {
  display: flex;
  align-self: stretch;
  padding: 0.75rem 0.75rem 0 0.75rem;

  @media (min-width: 768px) {
    padding: 0.75rem 1.5rem 0 1.5rem;
  }

  @media (min-width: 1024px) {
    padding: 1rem 2.5rem 0.5rem 2.5rem;
  }

  .banner {
    display: flex;
    width: 100%;
    height: 5.25rem;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border-radius: 0.5rem;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #00132B80;
    }

    @media (min-width: 1024px) {
      height: 6.5rem;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    gap: 0.125rem;
    padding: 0.5rem 0.75rem;
    z-index: 1;
  }

  .textLine {
    display: block;

    @media (min-width: 768px) {
      display: inline;
    }
  }

  .textSuperscript {
    position: relative;
    top: -0.3125rem;
    font-size: 63%;
    line-height: normal;
  }

  .buttonContainer {
    &:focus-visible {
      outline: 1px dashed $white;
      outline-offset: -1px;

      .buttonText p {
        text-decoration: none;
      }
    }
  }

  .buttonContent {
    display: flex;
    gap: 0.625rem;
  }

  .buttonText p {
    text-align: center;
  }

  .buttonIcon {
    height: 1.125rem;
    width: 1.125rem;
    margin-left: unset;
  }
}

.clickableBanner {

  &:hover::before,
  &:focus-visible::before,
  &:active::before {
    background: #00132B99;
  }

  &:focus-visible {
    outline: none;

    .content {
      outline: 0.125rem dashed $white;
      outline-offset: -0.125rem;
    }
  }

  &:focus-visible,
  &:active {
    .buttonContainer {
      outline: none;

      .buttonText p {
        text-decoration: underline;
      }
    }
  }
}
