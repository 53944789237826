@import '../../../resources/scss/colors.scss';
@import '../../../resources/scss/z-indexes.scss';

.expandableContent {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  .contentWithMaxLines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    &.contentWith3MaxLines {
      -webkit-line-clamp: 3;
      line-clamp: 3;
      max-height: 3.9375rem; // 3 x 21px line height
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 !important; // Override inline styles for padding that are applied by modal
  background: $core-blue-70;
  z-index: $modal-z-index;

  .modalDialog {
    width: 100%;
    height: 100%;
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 0.625rem 0.5rem 0.625rem 1rem;
    background: #00132BE5;
    backdrop-filter: blur(1.25rem);
  }

  .closeButton {
    background-color: transparent;

    &:active,
    &:hover {
      border-radius: 0.25rem;
      background-color: #ffffff33;
    }

    &:focus-visible {
      border-radius: 0.25rem;
      outline: 1px dashed $white;
      outline-offset: -1px;
    }

    .closeIcon {
      width: 2rem;
      height: 2rem;
    }
  }

  .expandedContent {
    padding: 1.5rem 1rem;
    flex-grow: 1;
  }
}

// CSS that is used to style content's HTML
.content {
  overflow-wrap: anywhere;

  ul li {
    list-style: disc;
  }
}
