@import '../../../resources/scss/colors.scss';

.tabBar {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.125rem;
    padding: 0.75rem 0.5rem calc(0.5rem - 1px) 0.5rem; // Account for 1px for bottom border
    border-bottom: 1px solid $digital-grey-10;
    cursor: pointer;

    @media (min-width: 1024px) {
      flex-direction: row;
      gap: 0.25rem;
      padding: 0.5rem 0.75rem calc(0.75rem - 1px) 0.75rem; // Account for 1px for bottom border
    }

    &:hover {
      .tabIcon path {
        fill: $text-subdued-dark;
      }

      .tabTitle p {
        color: $text-subdued-dark;
      }
    }

    &:focus-visible {
      outline: 0.125rem dashed $interaction-blue-50;
      outline-offset: -0.125rem;

      .tabIcon path {
        fill: $text-subdued-dark;
      }

      .tabTitle p {
        color: $text-subdued-dark;
      }
    }

    &:active {
      background-color: $interaction-blue-10;

      .tabIcon path {
        fill: $interaction-blue-50;
      }

      .tabTitle p {
        color: $interaction-blue-50;
      }
    }
  }

  .activeTab {
    padding-bottom: calc(0.5rem - 0.125rem); // Account for 2px for bottom border
    border-bottom: 0.125rem solid $interaction-blue-50;

    @media (min-width: 1024px) {
      padding-bottom: calc(0.75rem - 0.125rem); // Account for 2px for bottom border
    }

    &:hover {
      .tabIcon path {
        fill: $interaction-blue-50;
      }

      .tabTitle p {
        color: $interaction-blue-50;
      }
    }

    &:focus-visible {
      border-bottom-style: dashed;

      .tabIcon path {
        fill: $interaction-blue-50;
      }

      .tabTitle p {
        color: $interaction-blue-50;
      }
    }
  }

  .tabIcon {
    width: 1.25rem;
    height: 1.25rem;

    @media (min-width: 1024px) {
      width: 1rem;
      height: 1rem;
    }
  }

  .tabTitle p {
    white-space: nowrap;
    min-width: 2.9375rem;

    @media (min-width: 1024px) {
      font-size: 0.875rem;
      line-height: 1.3125rem;
      min-width: unset;
    }
  }
}
