.footerMenuList {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  flex: 1 1 auto;
  gap: 1rem;
}

.button {
  padding: 0;
}
