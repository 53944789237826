@import '../../../resources/scss/colors.scss';

$max-content-width-desktop: 60rem;
$max-content-width-desktop-1440px: 84rem;

.heroBanner {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 23.75rem;
  padding: 4rem 1rem;
  background-color: $core-blue-70;
  background-size: 1129px 353px;
  background-position-x: 91%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 767px) {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #00132b99;
      z-index: 1;
    }
  }

  @media (min-width: 390px) {
    background-position-x: right;
  }

  @media (min-width: 768px) {
    min-height: 25rem;
    padding: 1rem 2rem;
    background-size: 1280px 400px;
  }

  @media (min-width: 1024px) {
    padding: 1rem calc((100% - #{$max-content-width-desktop}) / 2);
  }

  @media (min-width: 1440px) {
    min-height: 37.5rem;
    padding: 1rem calc((100% - #{$max-content-width-desktop-1440px}) / 2);
    background-size: 1920px 600px;
  }
}

.heroBannerContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 2;

  @media (min-width: 768px) {
    max-width: 27.5rem;
  }

  @media (min-width: 1440px) {
    max-width: 41.25rem;
  }
}

.titleContainer {
  padding-bottom: calc(1rem - 2px); // Account for 2px for bottom border
  border-bottom: 2px solid $interaction-red-50;
}
