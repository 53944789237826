@import '../../../resources/scss/colors.scss';

$border-color: $digital-grey-10;

$max-content-width-desktop: 55.5rem;

// On mobiles the page content takes full width of the screen.
// On desktops the page content has max width of 55.5 rems and is horizontally centered on the screen.
.pageContentContainerLayout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  flex: 1 1 auto;
  background-color: $white;

  @media (min-width: 1024px) {
    width: $max-content-width-desktop;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    box-sizing: content-box;
    // Set an explicit left margin (not 'auto') so that scrollbar does not shift the content
    // Use 100vw instead of 100% because 100vw is no affected by scrollbar
    margin-left: calc((100vw - #{$max-content-width-desktop}) / 2); // Half of the remaining screen width
  }
}
