.landingPageBannerBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  background-color: rgb(244, 244, 244);

  .landingPageBanner {
    display: flex;
    width: unset;
    height: 6.25rem;
    align-self: stretch;
  }
  
  @media (min-width: 1024px) {

    .landingPageBanner {
      height: 15rem;
    }
    
  }
}
