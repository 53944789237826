@import '../../../resources/scss/colors.scss';
@import '../../../resources/scss/z-indexes.scss';

.accountMenu {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media (min-width: 1024px) {
    cursor: pointer;
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-left: -0.5rem;

  @media (min-width: 1024px) {
    padding-right: 0.5rem;
  }

  .logo {
    width: 7.75rem;
    height: 1.25rem;

    @media (min-width: 1024px) {
      width: 9.375rem;
      height: 1.5rem;
    }
  }
}

.greetingFirstNameContainer {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;

  .greetingFirstName p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.toggleButton {
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    align-self: center;
    padding: 0;

    &:hover,
    &:active,
    &:focus-visible {
      border-radius: 0;
      background-color: unset;
      outline: none;
      outline-offset: 0;
    }

    .menuButtonIcon {
      width: 1.25rem;
      height: 1.25rem;
      line-height: 0;
    }
  }
}

.accountMenuCollapsed {
  .greetingFirstName {
    display: none;

    @media (min-width: 1024px) {
      display: flex;
      align-self: stretch;
      width: unset;
    }
  }
}

.accountMenuExpanded {
  .greetingFirstName {
    display: flex;
    align-self: stretch;
    width: unset;
  }

  .closeButton {
    display: flex;

    @media (min-width: 1024px) {
      display: none;
    }

    .menuButtonIcon {
      width: 1.5rem;
      height: 1.5rem;
      line-height: 0;
    }
  }
}

.dropdown {
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.dropdownToggle {
  display: flex;
  padding: 0.5rem 0 0.5rem 0.5rem;
  overflow: hidden;

  &:hover {
    background: #FFFFFF1A;
  }

  &:active {
    background: #FFFFFF26;
  }

  &:focus-visible {
    background: #FFFFFF1A;
    outline: 1px dashed $white;
    outline-offset: -1px;
  }
}

.dropdownMenu {
  z-index: $top-nav-dropdown-menu-z-index;
  width: 21.5rem;
  transform: translate3d(-1rem, var(--top-nav-height), 0) !important; // Account for the height of the header. Do not account for the height of the top disclaimer.
  box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.75rem;
}

.dropdownMenu> :first-child {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  padding-top: 0.75rem;
}

.dropdownMenu> :last-child {
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  padding-bottom: calc(0.75rem - 1px); // Account for 1px for My Account bottom border
}

.myAccount,
.myTickets,
.logOut {
  cursor: pointer;
  align-self: stretch;
}
