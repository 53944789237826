$carouselItemsContainerPaddingY: 1rem;

.carousel {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
}

@media (min-width: 1024px) {

  // Since carousel items are rendered within a container with "overflow: hidden" on desktops, all box shadows on carousel items get cut off.
  // So we render additional box shadows before and after the carousel to achieve the box shadow effect.
  .carouselWithLeftBoxShadow::before,
  .carouselWithRightBoxShadow::after {
    content: '';
    width: 0.75rem;
    height: calc(100% - 2 * #{$carouselItemsContainerPaddingY}); // Reduce height by 2 * 1rem of top and bottom paddings of carousel items container (see .carouselItemsContainer class)
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
    pointer-events: none;
    position: absolute;
    top: 1rem;
    // This z-index must be less than z-index of Carousel's .carouselItem class (see below)
    z-index: 1;
  }

  .carouselWithLeftBoxShadow::before {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    left: 0;
  }

  .carouselWithRightBoxShadow::after {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    right: 0;
  }
}

.carouselItemsContainer {
  display: flex;
  padding-top: $carouselItemsContainerPaddingY;
  padding-bottom: $carouselItemsContainerPaddingY;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  // On desktops we disable free scrolling and switch to scrolling with backward/forward buttons
  @media (min-width: 1024px) {
    overflow-x: hidden;
  }
}

.carouselItem {
  display: flex;
  width: 100%;
  scroll-snap-align: center;
  // This z-index must be greater than z-index of Carousel's .carousel::before/after class (see above)
  // This z-index must be less than z-index of CarouselButton's .carouselButton class
  z-index: 2;

  @media (min-width: 1024px) {
    scroll-snap-align: start;
  }
}

.carouselLoadingOrEmptyStateContainer {
  padding: 1rem;

  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }

  @media (min-width: 1024px) {
    padding: 1rem 0;
  }
}

// Custom CSS for empty state
.carouselEmptyState {
  margin: inherit !important; // Override margin
  height: inherit;
}

.mobileScrollButtonContainer {
  display: flex;
  align-items: center;
  padding-bottom: $carouselItemsContainerPaddingY;

  .mobileScrollButton {

    &:active,
    &:hover {
      background-color: transparent;
      border-radius: 0;
    }
  }

  .mobileScrollButtonDisabled svg path {
    fill-opacity: 0.2;
  }

  .mobileScrollButtonIcon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .unselectedMobileScrollDot svg circle[class="fill"] {
    fill: transparent;
  }
}
