@import '../../../resources/scss/colors.scss';
@import '../../../resources/scss/z-indexes.scss';

$max-width: 30.3125rem;

.modal {
  display: flex !important; // Override inline styles for display that are applied by modal
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000040;
  z-index: $modal-z-index;
}

.modalDialog {
  display: flex;
  max-width: $max-width;
  margin: 1rem;

  @media (min-width: 768px) {
    width: $max-width;
  }
}

.modalContent {
  width: 100%;
}

.ticketAlertModal {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: $white;
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 1rem 0 #00000033;

  .closeButton {
    display: flex;
    align-self: flex-end;
    padding: 0;
    background-color: transparent;

    &:hover {
      border-radius: 0.25rem;
      background-color: $custom-blue-10;
    }

    &:focus-visible {
      border-radius: 0.25rem;
      background-color: $custom-blue-10;
      outline: 1px dashed $interaction-blue-50;
      outline-offset: -1px;
    }

    &:active {
      border-radius: 0.25rem;
      background-color: $interaction-blue-10;
    }

    .closeButtonIcon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .header {
    display: flex;
    align-items: center;
    gap: 0.625rem;

    .headerIcon {
      width: 2.625rem;
      height: 2.625rem;
    }
  }

  .primaryButton {
    display: flex;
    align-self: flex-end;
    margin-top: 3rem;

    &:focus-visible {
      outline: 0.125rem dashed #FF9900;
      outline-offset: 0;
    }
  }
}
