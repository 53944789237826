.myTicketsBlockEmpty {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // padding: 1rem 0rem 1rem 0rem; // MANUAL OVERRIDE
  background-color: rgb(244, 244, 244);

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .tabList {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .emptyStateBanner {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  @media (min-width: 1024px) {
    // padding: 1.5rem 0rem 1.5rem 0rem; // MANUAL OVERRIDE

  }
}

.myTicketsBlockFilled {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(244, 244, 244);

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .tabList {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .eventsCardList {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
}
