@import '../../../resources/scss/colors.scss';

.noListings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 1rem;

  .message {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  .goToHomeButton {
    width: 16.25rem;
    max-width: 100%;

    @media (min-width: 1024px) {
      width: 100%;
    }

    .goToHomeButtonText {
      text-align: center;
    }
  }
}
