@import '../../../resources/scss/colors.scss';

.exclusiveBadge {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem 0.75rem 0.25rem 0.5rem;
  border-radius: 6.25rem;
  box-shadow: 0 0 1px 0 #00000014;
  width: fit-content;
  box-sizing: border-box;
  max-width: 100%;
}

.lightExclusiveBadge {
  background-color: $white;
}

.darkExclusiveBadge {
  background-color: $interaction-blue-10;
}

.imageContainer {
  display: flex;
  height: 1rem;
  width: 1rem;
  justify-content: center;
  align-items: center;

  .image {
    width: 0.9375rem;
    height: 0.5625rem;
    border-radius: 0.67px;
  }
}

.unShrinkableIconAndImage {
  flex-shrink: 0;
}

.badgeText {
  overflow: hidden;

  p {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}