.image {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  // MANUAL OVERRIDE - removed fix height and width

}
