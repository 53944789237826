.sportsMenuTabList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 10.9375rem; // It is 176px - 1px for right border

  .submenuTabItem {
    display: flex;
    width: 10.9375rem; // It is 176px - 1px for right border
    align-self: stretch;
  }

}

@media (min-width: 1440px) {
  .sportsMenuTabList {
    width: 16.25rem; // It is 261px - 1px for right border

    .submenuTabItem {
      width: 16.25rem; // It is 261px - 1px for right border
    }

  }
}
