.eventsTotalContainer {
  display: flex;
  align-self: stretch;
  padding: 1rem;

  @media (min-width: 1024px) {
    padding: 1rem 1.5rem;
  }
}

.resultsMessage {
  h2 {
    text-align: left !important; // Override CSS from Text component
  }
}

.searchQuery {
  font-weight: 600;
}
