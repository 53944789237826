.accountCard {
  display: flex;
  gap: 0.5rem;
}

.accountCardImage {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1rem;
  margin: 0.25rem 0;
  border-radius: 1px;
}

.lightAccountCardImage {
  outline: 1px solid #FFFFFF1A;
}

.accountCardInfoContainer {
  display: flex;
  padding-top: 0.125rem;
}

.boldRewardUnits {
  font-weight: 600;
}
