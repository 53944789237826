@import '../../../resources/scss/colors.scss';

.subTotalContainer {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.subTotal {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.rightContent {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  gap: 0.5rem;
  row-gap: 0;
  flex-wrap: wrap;
  padding-top: 0.375rem; // 6px as a difference between Subtotal line height (30px) and price line height (24px)
}

.loyaltyPrice {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  gap: 0.25rem;
}

.serviceFeeText {
  justify-content: flex-end;
}
