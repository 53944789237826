@import '../../../resources/scss/colors.scss';

.signInBanner {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: calc(2rem - 1px) 1rem 2rem 1rem; // Account for 1px for top border
  border-top: 1px solid $grey-20;
  box-sizing: border-box;

  .contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    gap: 1rem;
    width: 100%;
    max-width: 37.8125rem;
    padding: 2rem 1rem 1rem 1rem;
    background-color: $interaction-blue-10;
    border-radius: 0.75rem;
    box-sizing: border-box;
  }

  .signInButton {
    width: 100%;
    height: 3rem;
    border-radius: 0.25rem;

    .signInButtonText p {
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.875rem;
    }
  }
}
