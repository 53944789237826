.submenuItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  margin-left: 0.75rem;
  cursor: pointer;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.25rem 0.5rem;
    width: 100%;
    flex: 1 1 auto;
    box-sizing: border-box;
  }

  .text {
    display: flex;
    width: unset;
    align-self: stretch;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &:active {
    background-color: rgb(244, 244, 244);
  }

  &:focus-visible {
    background-color: rgb(244, 244, 244);
    outline: 2px dashed rgb(16, 37, 63);
    outline-offset: 0;
  }

  &:hover {
    background-color: rgb(244, 244, 244);
  }

  @media (min-width: 1440px) {
    margin-left: 0.625rem;
  }
}
